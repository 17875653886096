import { Well } from '../model/Well';

export const WELLS_LOCAL_STORAGE_KEY = 'WELLS_DATA';

type findWellByIdReturn = Well | null;

class WellRepository {
	findWells(): Array<Well> {
		const data = localStorage.getItem(WELLS_LOCAL_STORAGE_KEY);
		if (data === null) return [];

		return JSON.parse(data) as Array<Well>;
	}

	findWellById(wellId: number): findWellByIdReturn {
		const wells = this.findWells();
		const well = wells.find((e) => e.id === wellId);
		if (well === undefined) return null;

		return well;
	}

	saveWell(well: Well) {
		const cur = this.findWellById(well.id);
		if (cur !== null) {
			// If the well already exists, then edit it.
			const wells = this.findWells().filter((e) => e.id !== well.id);
			wells.push(well);
			localStorage.setItem(
				WELLS_LOCAL_STORAGE_KEY,
				JSON.stringify(wells)
			);
		} else {
			// If the well does not exist already, then create it anew.
			const wells = this.findWells();
			wells.push(well);
			localStorage.setItem(
				WELLS_LOCAL_STORAGE_KEY,
				JSON.stringify(wells)
			);
		}
	}

	deleteWell(well: Well) {
		if (this.findWells().length === 1)
			throw new Error(
				'You need to have at least 1 well to use DensiCalc.'
			);
		const cur = this.findWellById(well.id);
		if (cur !== null) {
			const wells = this.findWells().filter((e) => e.id !== well.id);
			localStorage.setItem(
				WELLS_LOCAL_STORAGE_KEY,
				JSON.stringify(wells)
			);
		}
	}
}

export default new WellRepository();
