import { useEffect, useContext } from 'react';
import { DEFAULT_WELL, WellContext } from '../providers/WellProvider';
import WellRepository from '../repositories/WellRepository';

export function useLoadWells() {
	const { setWells } = useContext(WellContext);

	useEffect(() => {
		const wells = WellRepository.findWells();
		if (wells.length === 0) {
			WellRepository.saveWell(DEFAULT_WELL);
			wells.push(DEFAULT_WELL);
		}

		setWells(wells);
	}, []);
}
