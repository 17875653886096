import { DensiCalcData } from '../model/DensiCalcData';

export const DENSI_CALC_DATA_LOCAL_STORAGE_KEY = 'DENSI_CALC_DATA';

export type findDensiCalcDataReturn = DensiCalcData | null;
export type findAllDensiCalcDataReturn = {
	[key: string]: DensiCalcData;
} | null;

class DensiCalcDataRepository {
	findDensiCalcData(wellId: number): findDensiCalcDataReturn {
		const data = localStorage.getItem(DENSI_CALC_DATA_LOCAL_STORAGE_KEY);
		if (data !== null) {
			const aux = JSON.parse(data);
			const densiCalcData = aux[wellId];
			if (densiCalcData === undefined) return null;

			return densiCalcData as DensiCalcData;
		}

		return null;
	}

	findAllDensiCalcData(): findAllDensiCalcDataReturn {
		const data = localStorage.getItem(DENSI_CALC_DATA_LOCAL_STORAGE_KEY);
		if (data !== null) {
			return JSON.parse(data);
		}

		return null;
	}

	saveDensiCalcData(wellId: number, densiCalcData: DensiCalcData) {
		const data = localStorage.getItem(DENSI_CALC_DATA_LOCAL_STORAGE_KEY);
		if (data !== null) {
			const aux = JSON.parse(data);
			aux[wellId] = densiCalcData;

			localStorage.setItem(
				DENSI_CALC_DATA_LOCAL_STORAGE_KEY,
				JSON.stringify(aux)
			);
		} else {
			const newData: any = {};
			newData[wellId] = densiCalcData;

			localStorage.setItem(
				DENSI_CALC_DATA_LOCAL_STORAGE_KEY,
				JSON.stringify(newData)
			);
		}
	}

	deleteDensiCalcData(wellId: number) {
		const data = localStorage.getItem(DENSI_CALC_DATA_LOCAL_STORAGE_KEY);
		if (data !== null) {
			const aux = JSON.parse(data);
			delete aux[wellId];

			localStorage.setItem(
				DENSI_CALC_DATA_LOCAL_STORAGE_KEY,
				JSON.stringify(aux)
			);
		}
	}
}

export default new DensiCalcDataRepository();
