import {
	calcRiserDensity,
	calcRiserMargin,
	calcSeabedPressure,
	calcTvdPressure,
	calculateDensity,
	calcWellboreDensity,
	convertToSelected,
	convertToSI,
	densityAnalysis,
	RigType,
	riserEqs,
	RiserEqsOutput,
	tvdTemperature,
	Unit,
	wellboreEqs,
	WellboreEqsOutput
} from '@fastsheep/densicalc-core';
import { addCommas, convertToNumber } from '../utils/NumberUtils';

export type InputType = 'density' | 'pressure' | 'temperature' | 'length';

export class Calculator {
	handleInput(input: string | number, unit: Unit): number {
		let n: number =
			typeof input === 'string' ? convertToNumber(input) : input;

		const result = convertToSI(n, unit);

		return result;
	}

	handleOutput(
		input: string | number,
		unit: Unit,
		significantFigures: number
	): string {
		if (typeof input === 'string' && input.length === 0) return '';

		let n: number =
			typeof input === 'string' ? convertToNumber(input) : input;

		const convertedValue = convertToSelected(n, unit);
		const result = convertedValue.toPrecision(significantFigures);

		if (`${result}`.includes('e+')) {
			const aux = parseFloat(`${result}`);
			return addCommas(`${aux}`);
		}
		return addCommas(result);
	}

	calculateDensity(density: number, temp: number): string {
		return (
			calculateDensity(convertToNumber(density), convertToNumber(temp)) +
			''
		);
	}

	densityAnalysis(
		measuredDensity: number,
		measuredTemp: number,
		referenceTemp: number
	): string {
		return (
			densityAnalysis(
				convertToNumber(measuredDensity),
				convertToNumber(measuredTemp),
				convertToNumber(referenceTemp)
			) + ''
		);
	}

	calcRiserDensity(
		correctDensity: number | string,
		seabedTemp: number,
		surfaceTemp: number,
		airgap: number,
		waterDepth: number,
		rkbFlow: number
	): { riserDensity: number; riserDensityStr: string } {
		const midValues = riserEqs(
			convertToNumber(correctDensity),
			convertToNumber(seabedTemp),
			convertToNumber(surfaceTemp),
			convertToNumber(airgap),
			convertToNumber(waterDepth),
			convertToNumber(rkbFlow)
		);

		const riserDensity = calcRiserDensity(
			convertToNumber(correctDensity),
			midValues.DLFT,
			midValues.DGFP
		);
		return { riserDensity, riserDensityStr: riserDensity.toPrecision(4) };
	}

	calcSeabedPressure(
		riserDensity: number,
		FCR: number
	): { seabedPressure: number; seabedPressureStr: string } {
		const seabedPressure = calcSeabedPressure(FCR, riserDensity);

		return {
			seabedPressure,
			seabedPressureStr: parseFloat(
				seabedPressure.toPrecision(4)
			).toString()
		};
	}

	riserEqs(
		correctDensity: string,
		seabedTemp: number,
		surfaceTemp: number,
		airgap: number,
		waterDepth: number,
		rkbFlow: number
	): RiserEqsOutput {
		return riserEqs(
			convertToNumber(correctDensity),
			seabedTemp,
			surfaceTemp,
			airgap,
			waterDepth,
			rkbFlow
		);
	}

	wellboreEqs(
		rigType: RigType,
		surfaceTemp: number,
		tvdTemperature: number,
		seabedTemp: number,
		tvd: number,
		rkbFlow: number,
		airgap: number,
		waterDepth: number,
		correctDensity: string,
		seabedPressure: number
	): WellboreEqsOutput {
		return wellboreEqs(
			rigType,
			convertToNumber(surfaceTemp),
			tvdTemperature,
			convertToNumber(seabedTemp),
			convertToNumber(tvd),
			convertToNumber(rkbFlow),
			convertToNumber(airgap),
			convertToNumber(waterDepth),
			convertToNumber(correctDensity),
			convertToNumber(seabedPressure)
		);
	}

	calcRiserMargin(
		FCR: number,
		correctDensity: string,
		waterDepth: number,
		FCB: number
	): { riserMargin: number; riserMarginStr: string } {
		const riserMargin = calcRiserMargin(
			FCR,
			convertToNumber(correctDensity),
			convertToNumber(waterDepth),
			FCB
		);
		return { riserMargin, riserMarginStr: riserMargin.toPrecision(4) };
	}

	calcTvdPressure(
		rigType: RigType,
		FCB: number,
		ESDWS: number,
		seabedPressure: number
	): { tvdPressure: number; tvdPressureStr: string } {
		const tvdPressure = calcTvdPressure(
			rigType,
			FCB,
			ESDWS,
			seabedPressure
		);
		return {
			tvdPressure,
			tvdPressureStr: tvdPressure.toString()
		};
	}

	calcTvdTemperature(
		rigType: RigType,
		surfaceTemp: number,
		bottomTemp: number,
		tvd: number,
		bottomTvd: number,
		seabedTemp: number,
		airgap: number,
		waterDepth: number
	): { tvdTemperature: number; tvdTemperatureStr: string } {
		const tvdTemp = tvdTemperature(
			rigType,
			surfaceTemp,
			bottomTemp,
			tvd,
			bottomTvd,
			seabedTemp,
			airgap,
			waterDepth
		);
		return {
			tvdTemperature: tvdTemp,
			tvdTemperatureStr: tvdTemp.toString()
		};
	}

	calcWellboreDensity(
		tvdPressure: number,
		FCT: number
	): { wellboreDensity: number; wellboreDensityStr: string } {
		const wellboreDensity = calcWellboreDensity(tvdPressure, FCT);

		return {
			wellboreDensity,
			wellboreDensityStr: wellboreDensity.toString()
		};
	}
}

export default new Calculator();
