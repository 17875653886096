import { useState, useContext } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeContext } from '../../../providers/ThemeProvider';

type GroupedButtonsProps = {
	onEditButtonClick: () => void;
	onDeleteButtonClick: () => void;
};
export default function GroupedButtons(props: GroupedButtonsProps) {
	const [dialOpen, setDialOpen] = useState<boolean>(false);

	const { colors } = useContext(ThemeContext);

	return (
		<SpeedDial
			ariaLabel="well-options-speeddial"
			sx={{
				position: 'fixed',
				bottom: 15,
				right: 90
			}}
			FabProps={{
				sx: {
					backgroundColor: '#E0E0E0',
					color: 'rgba(0, 0, 0, 0.87)',
					':hover': {
						color: 'white',
						backgroundColor: colors.primaryColor
					}
				}
			}}
			open={dialOpen}
			onClose={() => setDialOpen(false)}
			onOpen={() => setDialOpen(true)}
			icon={
				<SpeedDialIcon openIcon={<CloseIcon />} icon={<EditIcon />} />
			}
		>
			<SpeedDialAction
				key="edit_well"
				tooltipTitle="Edit well description"
				icon={<EditIcon />}
				onClick={props.onEditButtonClick}
			/>

			<SpeedDialAction
				key="delete_well"
				tooltipTitle="Delete selected well"
				icon={<DeleteIcon />}
				onClick={props.onDeleteButtonClick}
			/>
		</SpeedDial>
	);
}
