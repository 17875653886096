import { useState } from 'react';
import { DeleteWellDialog } from './DeleteWellDialog';
import EditWellDialog from './EditWellDialog';
import DensiCalcOptionsRepository from '../../repositories/DensiCalcOptionsRepository';
import { getDefaultDensiCalcOptions } from '../../model/Options';
import GroupedButtons from './GroupedButtons';
import UngroupedButtons from './UngroupedButtons';

export default function WellOptions() {
	const savedOptions = DensiCalcOptionsRepository.getDensiCalcOptions();
	const options = savedOptions ? savedOptions : getDefaultDensiCalcOptions();

	const [deleteWellDialogOpen, setDeleteWellDialogOpen] =
		useState<boolean>(false);
	const [editWellDialogOpen, setEditWellDialogOpen] =
		useState<boolean>(false);

	return (
		<>
			{options.groupedButtons ? (
				<GroupedButtons
					onEditButtonClick={() => setEditWellDialogOpen(true)}
					onDeleteButtonClick={() => setDeleteWellDialogOpen(true)}
				/>
			) : (
				<UngroupedButtons
					onEditButtonClick={() => setEditWellDialogOpen(true)}
					onDeleteButtonClick={() => setDeleteWellDialogOpen(true)}
				/>
			)}
			<DeleteWellDialog
				open={deleteWellDialogOpen}
				onClose={() => setDeleteWellDialogOpen(false)}
			/>
			<EditWellDialog
				open={editWellDialogOpen}
				onClose={() => setEditWellDialogOpen(false)}
			/>
		</>
	);
}
