import React, { useId, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps, Theme } from '@mui/material';

type SectionProps = {
	children: React.ReactNode;
	title: string;
	sx?: SxProps<Theme>;
};
export default function Section(props: SectionProps) {
	const id = useId();
	const [expanded, setExpanded] = useState<boolean>(true);
	return (
		<Accordion
			expanded={expanded}
			onChange={() => setExpanded((prev) => !prev)}
			sx={props.sx}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`panel-${id}-content`}
				id={`panel-${id}-header`}
			>
				<Typography variant="h5">{props.title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{props.children}</AccordionDetails>
		</Accordion>
	);
}
