import { InputBaseProps, SxProps } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Theme, SystemCssProperties } from '@mui/system';

export type TextFieldProps = {
	id: string;
	value: string;
	onChange?: (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
	label?: string;
	sx?: SxProps<Theme>;
	inputProps: InputBaseProps['inputProps'];
	disabled?: boolean;
	readOnly?: boolean;
};
export default function TextField(props: TextFieldProps) {
	return (
		<Stack direction="row" alignItems="center">
			<Typography
				align="right"
				sx={{
					width: { md: '20vw', lg: '10vw' },
					color: props.disabled ? 'gray' : undefined
				}}
			>
				{props.label}
			</Typography>
			<MuiTextField
				id={props.id}
				data-cy={props.id}
				value={props.value}
				onChange={props.onChange}
				sx={{
					...props.sx,
					backgroundColor: props.disabled
						? 'rgba(128, 128, 128, 0.5)'
						: ({ ...props.sx } as SystemCssProperties)[
								'backgroundColor'
						  ],
					width: '15vw',
					marginLeft: { md: '2vw', lg: '1vw' }
				}}
				inputProps={props.inputProps}
				size="small"
				disabled={props.disabled}
				InputProps={{ readOnly: props.readOnly }}
			/>
		</Stack>
	);
}
