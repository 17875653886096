import { DefaultUnits } from '../model/DefaultUnits';

export const DEFAULT_UNITS_LKS = 'DENSI_CALC_DEFAULT_UNITS';

class DefaultUnitsRepository {
	saveDefaultUnits(defaultUnits: DefaultUnits): void {
		localStorage.removeItem(DEFAULT_UNITS_LKS);
		localStorage.setItem(DEFAULT_UNITS_LKS, JSON.stringify(defaultUnits));
	}

	findDefaultUnits(): DefaultUnits | null {
		const data = localStorage.getItem(DEFAULT_UNITS_LKS);
		if (data) {
			return JSON.parse(data) as DefaultUnits;
		}

		return null;
	}
}

export default new DefaultUnitsRepository();
