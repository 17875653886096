import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { SxProps, Theme } from '@mui/material';
import { DensityUnit, DENSITY_UNITS } from '@fastsheep/densicalc-core';

type DensityUnitSelectorProps = {
	value: DensityUnit;
	onChange: (value: DensityUnit) => void;
	sx?: SxProps<Theme>;
	selectorSx?: SxProps<Theme>;
	id?: string;
	label?: string;
	size?: 'small' | 'medium' | undefined;
	disabled?: boolean;
};
export default function DensityUnitSelector(props: DensityUnitSelectorProps) {
	const onChange = (
		event: SelectChangeEvent<DensityUnit>,
		child: React.ReactNode
	) => {
		props.onChange(event.target.value as DensityUnit);
	};
	// @ts-ignore
	const width = props.sx && props.sx.width ? props.sx.width : '10vw';
	return (
		<FormControl sx={{ ...props.sx, width: width }}>
			<InputLabel id="density_unit_label">
				{props.label ? props.label : ''}
			</InputLabel>
			<Select
				labelId="density_unit_label"
				size={props.size ? props.size : 'small'}
				label={props.label ? props.label : ''}
				name={props.id ? props.id : 'density_unit'}
				id={props.id ? props.id : 'density_unit'}
				data-cy={props.id ? props.id : 'density_unit'}
				value={props.value}
				onChange={onChange}
				sx={props.selectorSx}
				disabled={props.disabled ? props.disabled : false}
			>
				{DENSITY_UNITS.map((unit, idx) => (
					<MenuItem
						key={idx}
						value={unit.code}
						id={unit.code}
						data-cy={unit.code}
					>
						{unit.displayName}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
