import { useState, useEffect, useContext } from 'react';
import Stack from '@mui/material/Stack';
import DensityUnitSelector from '../../selectors/DensityUnitSelector';
import {
	DensityUnit,
	LengthUnit,
	PressureUnit,
	TemperatureUnit
} from '@fastsheep/densicalc-core';
import TemperatureUnitSelector from '../../selectors/TemperatureUnitSelector';
import PressureUnitSelector from '../../selectors/PressureUnitSelector';
import LengthUnitSelector from '../../selectors/LengthUnitSelector';
import DefaultUnitRepository from '../../../repositories/DefaultUnitRepository';
import { DensiCalcContext } from '../../../providers/DensiCalcProvider';

export default function DefaultUnitSettings() {
	const [defaultDensityUnit, setDefaultDensityUnit] =
		useState<DensityUnit>('gcm');
	const [defaultTemperatureUnit, setDefaultTemperatureUnit] =
		useState<TemperatureUnit>('c');
	const [defaultPressureUnit, setDefaultPressureUnit] =
		useState<PressureUnit>('psi');
	const [defaultLengthUnit, setDefaultLengthUnit] = useState<LengthUnit>('m');

	const {
		setDensityUnit,
		setMeasuredAtTempUnit,
		setResultDensityUnit,
		setReferenceTemperatureUnit,
		setDensityAtRefTempUnit,

		setAirgapUnit,
		setRkbToFlowlineUnit,
		setWaterDepthUnit,
		setSurfaceTemperatureUnit,
		setSeabedTemperatureUnit,
		setDownholeTemperatureUnit,
		setDownholeTempMeasuredAtDepthUnit,
		setAnalysisPointUnit,
		setAverageRiserDensityUnit,
		setFluidPressureAtSeabedUnit,
		setRiserMarginUnit,
		setAvgDensityAboveTvdUnit,
		setPressureAtTvdUnit,
		setTemperatureAtTvdUnit
	} = useContext(DensiCalcContext);

	useEffect(() => {
		const defaultUnits = DefaultUnitRepository.findDefaultUnits();
		if (!defaultUnits) {
			DefaultUnitRepository.saveDefaultUnits({
				densityUnit: 'gcm',
				temperatureUnit: 'c',
				pressureUnit: 'psi',
				lengthUnit: 'm'
			});
		} else {
			setDefaultDensityUnit(defaultUnits.densityUnit);
			setDefaultTemperatureUnit(defaultUnits.temperatureUnit);
			setDefaultPressureUnit(defaultUnits.pressureUnit);
			setDefaultLengthUnit(defaultUnits.lengthUnit);
		}
	}, []);

	const onDefaultDensityUnitChange = (unit: DensityUnit) => {
		DefaultUnitRepository.saveDefaultUnits({
			densityUnit: unit,
			temperatureUnit: defaultTemperatureUnit,
			pressureUnit: defaultPressureUnit,
			lengthUnit: defaultLengthUnit
		});
		setDefaultDensityUnit(unit);

		setDensityUnit(unit);
		setResultDensityUnit(unit);
		setDensityAtRefTempUnit(unit);

		setAverageRiserDensityUnit(unit);
		setRiserMarginUnit(unit);
		setRiserMarginUnit(unit);
		setAvgDensityAboveTvdUnit(unit);
	};

	const onDefaultTemperatureUnitChange = (unit: TemperatureUnit) => {
		DefaultUnitRepository.saveDefaultUnits({
			densityUnit: defaultDensityUnit,
			temperatureUnit: unit,
			pressureUnit: defaultPressureUnit,
			lengthUnit: defaultLengthUnit
		});
		setDefaultTemperatureUnit(unit);

		setMeasuredAtTempUnit(unit);
		setReferenceTemperatureUnit(unit);
		setSurfaceTemperatureUnit(unit);
		setSeabedTemperatureUnit(unit);
		setDownholeTemperatureUnit(unit);
		setTemperatureAtTvdUnit(unit);
	};

	const onDefaultPressureUnitChange = (unit: PressureUnit) => {
		DefaultUnitRepository.saveDefaultUnits({
			densityUnit: defaultDensityUnit,
			temperatureUnit: defaultTemperatureUnit,
			pressureUnit: unit,
			lengthUnit: defaultLengthUnit
		});
		setDefaultPressureUnit(unit);

		setFluidPressureAtSeabedUnit(unit);
		setPressureAtTvdUnit(unit);
	};

	const onDefaultLengthUnitChange = (unit: LengthUnit) => {
		DefaultUnitRepository.saveDefaultUnits({
			densityUnit: defaultDensityUnit,
			temperatureUnit: defaultTemperatureUnit,
			pressureUnit: defaultPressureUnit,
			lengthUnit: unit
		});
		setDefaultLengthUnit(unit);

		setAirgapUnit(unit);
		setRkbToFlowlineUnit(unit);
		setWaterDepthUnit(unit);
		setDownholeTempMeasuredAtDepthUnit(unit);
		setAnalysisPointUnit(unit);
	};

	return (
		<Stack
			direction="row"
			justifyContent="space-around"
			data-cy="default_unit_options_section"
		>
			<DensityUnitSelector
				label="Density"
				id="default_density_unit_selector"
				value={defaultDensityUnit}
				onChange={onDefaultDensityUnitChange}
				sx={{ width: '100px' }}
			/>
			<TemperatureUnitSelector
				label="Temperature"
				id="default_temperature_unit_selector"
				value={defaultTemperatureUnit}
				onChange={onDefaultTemperatureUnitChange}
				sx={{ width: '100px' }}
			/>
			<PressureUnitSelector
				label="Pressure"
				id="default_pressure_unit_selector"
				value={defaultPressureUnit}
				onChange={onDefaultPressureUnitChange}
				sx={{ width: '100px' }}
			/>
			<LengthUnitSelector
				label="Length"
				id="default_length_unit_selector"
				value={defaultLengthUnit}
				onChange={onDefaultLengthUnitChange}
				sx={{ width: '100px' }}
			/>
		</Stack>
	);
}
