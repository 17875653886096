import { convertToNumber } from './NumberUtils';

export function getNextId(): number {
	let currentIdStr = localStorage.getItem('LAST_ID');
	let currentId: number = 0;

	if (currentIdStr !== null) currentId = convertToNumber(`${currentIdStr}`);

	localStorage.setItem('LAST_ID', `${currentId + 1}`);

	return currentId + 1;
}
