import { useContext, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import { DEFAULT_WELL, WellContext } from '../../providers/WellProvider';
import { Well } from '../../model/Well';
import CreateWellDialog from '../CreateWellDialog';

export default function WellTabs() {
	const { wells, selectedWell, selectWell } = useContext(WellContext);

	const [createWellDialogOpen, setCreateWellDialogOpen] =
		useState<boolean>(false);

	const handleOnChange = (event: React.SyntheticEvent, value: any) => {
		if (value === -2) return;

		let w: Well | undefined = wells.find((e) => e.id === value);
		if (!w) w = DEFAULT_WELL;

		selectWell(w);
	};

	return (
		<>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				value={selectedWell.id}
				onChange={handleOnChange}
				sx={{ borderRight: 1, borderColor: 'divider' }}
			>
				{wells
					.sort((a, b) => a.id - b.id)
					.map((well, idx) => (
						<Tab
							key={idx}
							label={well.description}
							value={well.id}
						/>
					))}
				<Tab
					label="Create new well"
					icon={<AddIcon />}
					iconPosition="start"
					onClick={() => setCreateWellDialogOpen(true)}
					value={-2}
				/>
			</Tabs>
			<CreateWellDialog
				open={createWellDialogOpen}
				onClose={() => setCreateWellDialogOpen(false)}
			/>
		</>
	);
}
