import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';
import BannerMobile from '../../assets/img/banner_mobile.png';
import BannerDesktop from '../../assets/img/DensiCalc Banner with web address.png';
import { useWindowSize } from '../../hooks/useWindowSize';

export default function Header() {
	const [width, height] = useWindowSize();
	const xcoord = (1580 * width) / 1920;
	const ycoord = (110 * height) / 993;

	return (
		<Box>
			{isMobile ? (
				<img
					alt="banner"
					src={BannerMobile}
					style={{ width: '100%' }}
				/>
			) : (
				<>
					<img
						alt="banner"
						src={BannerDesktop}
						style={{ width: '100%' }}
						useMap="#webAddress"
					/>
					<map name="webAddress">
						<area
							shape="rect"
							coords={`${xcoord},${ycoord},${xcoord + 250},${
								ycoord + 80
							}`}
							href="https://formatebrines.com/"
							alt="Link para o site formatebrines.com"
							target="_blank"
						/>
					</map>
				</>
			)}
		</Box>
	);
}
