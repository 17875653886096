import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { useContext } from 'react';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { WellContext } from '../../../providers/WellProvider';
import { Well } from '../../../model/Well';
import DensiCalcDataRepository, {
	findAllDensiCalcDataReturn,
	findDensiCalcDataReturn
} from '../../../repositories/DensiCalcDataRepository';
import WellRepository from '../../../repositories/WellRepository';
import { getNextId } from '../../../utils/SequenceUtils';
import Alert from '../../Alert';
import WellSelectorModal from './WellSelectorModal';
import { DensiCalcData } from '../../../model/DensiCalcData';
import { getDefaultDensiCalcOptions } from '../../../model/Options';

function download(filename: string, text: string) {
	const el = document.createElement('a');
	el.setAttribute(
		'href',
		`data:text/json;charset=utf-8,${encodeURIComponent(text)}`
	);
	el.setAttribute('download', filename);
	el.style.display = 'none';
	el.click();
}

export type ExportedDataType = {
	wells: Array<Well>;
	densiCalcData: findAllDensiCalcDataReturn;
};
export type ImportExportSettingsProps = {
	onClose: () => void;
};
export default function ImportExportSettings({
	onClose
}: ImportExportSettingsProps) {
	const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
	const { colors } = useContext(ThemeContext);
	const { setWells } = useContext(WellContext);

	const [successfullyExported, setSuccessfullyExported] =
		useState<boolean>(false);
	const [successfullyImported, setSuccessfullyImported] =
		useState<boolean>(false);

	const handleOnExportButton = (wells: Well[]) => {
		const aux = DensiCalcDataRepository.findAllDensiCalcData();
		const densiCalcData: findAllDensiCalcDataReturn = {};
		for (const well of wells) {
			if (aux) {
				densiCalcData[well.id] = aux[well.id];
			}
		}

		const exportedData: ExportedDataType = {
			wells: wells,
			densiCalcData: densiCalcData
		};

		download(
			`${Date.now()}_densicalc_data.dcd`,
			JSON.stringify(exportedData)
		);
		onClose();
		setSuccessfullyExported(true);
		setTimeout(() => {
			setSuccessfullyExported(false);
		}, 3000);
	};

	const importData = (data: ExportedDataType) => {
		for (const well of data.wells) {
			const previousWellId = well.id;
			const wellId = getNextId();
			WellRepository.saveWell({ ...well, id: wellId });

			if (data.densiCalcData) {
				const densiCalcData = data.densiCalcData[previousWellId];
				if (densiCalcData) {
					DensiCalcDataRepository.saveDensiCalcData(
						wellId,
						densiCalcData
					);
				}
			}
		}

		setWells(WellRepository.findWells());
		onClose();
		setSuccessfullyImported(true);
		setInterval(() => {
			setSuccessfullyImported(false);
		}, 3000);
	};

	const handleOnImportButton = () => {
		const el = document.createElement('input');
		el.setAttribute('type', 'file');
		el.setAttribute('id', 'file-input');
		el.setAttribute('accept', '.dcd'); /*dcd -> densi calc data */
		el.onchange = (e: any) => {
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.onload = (e2: any) => {
				const _data = e2.target.result;
				console.log('contents => ', _data);
				if (_data != null) {
					if (isFileValid(_data)) {
						importData(JSON.parse(_data) as ExportedDataType);
					} else {
						alert(
							'File is invalid. Make sure you are importing a file generated by DensiCalc.'
						);
						return;
					}
				}
			};

			reader.readAsText(file);
		};
		el.style.display = 'none';
		el.click();
	};

	const isFileValid = (data: string): boolean => {
		const obj = JSON.parse(data);
		if (!obj.wells) return false;
		if (!obj.densiCalcData) return false;

		return true;
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexFlow: 'row no-wrap',
					height: '100%',
					width: '100%',
					justifyContent: 'space-around',
					alignItems: 'center'
				}}
			>
				<Stack direction="column" alignItems="center">
					<IconButton
						onClick={() => setExportDialogOpen(true)}
						size="large"
						sx={{
							color: 'white',
							backgroundColor: colors.primaryColor,
							width: '80px',
							height: '80px',
							borderRadius: '8px',
							'&:hover': {
								backgroundColor: 'gray',
								color: 'white'
							}
						}}
					>
						<DownloadIcon />
					</IconButton>
					<Typography>Export data</Typography>
				</Stack>
				<Stack direction="column" alignItems="center">
					<IconButton
						onClick={handleOnImportButton}
						size="large"
						sx={{
							color: 'white',
							backgroundColor: colors.primaryColor,
							width: '80px',
							height: '80px',
							borderRadius: '8px',
							'&:hover': {
								backgroundColor: 'gray',
								color: 'white'
							}
						}}
					>
						<UploadIcon />
					</IconButton>
					<Typography>Import data</Typography>
				</Stack>
			</Box>

			{successfullyImported && (
				<Alert severity="success">Successfully imported data.</Alert>
			)}
			{successfullyExported && (
				<Alert severity="success">Successfully exported data.</Alert>
			)}

			<WellSelectorModal
				onConfirm={handleOnExportButton}
				open={exportDialogOpen}
				onClose={() => setExportDialogOpen(false)}
			/>
		</>
	);
}
