import { useState, useContext, useId } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { WellContext } from '../../providers/WellProvider';
import { Well } from '../../model/Well';
import { getNextId } from '../../utils/SequenceUtils';
import WellRepository from '../../repositories/WellRepository';

const WELL_DESCRIPTION_MAX_SIZE = 50;

interface CreateWellDialogProps {
	open: boolean;
	onClose: () => void;
}
export default function CreateWellDialog(props: CreateWellDialogProps) {
	const [wellDescription, setWellDescription] = useState<string>('');
	const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const { addWell } = useContext(WellContext);

	const handleOnSaveWellButtonClick = () => {
		const newWell: Well = { id: getNextId(), description: wellDescription };
		addWell(newWell);
		WellRepository.saveWell(newWell);
		setWellDescription('');
		setSnackbarOpen(true);
		props.onClose();
	};

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose}>
				<DialogTitle>Create new well</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Enter the description of the new well and click on the
						save button.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="new_well_description"
						label="Well description"
						variant="standard"
						helperText={`${wellDescription.length} of ${WELL_DESCRIPTION_MAX_SIZE}.`}
						value={wellDescription}
						onChange={(event) => {
							if (
								event.target.value.length <=
								WELL_DESCRIPTION_MAX_SIZE
							)
								setWellDescription(event.target.value);
						}}
						required
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button color="error" onClick={props.onClose}>
						Cancel
					</Button>
					<Button
						onClick={handleOnSaveWellButtonClick}
						disabled={wellDescription.length === 0}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<MuiAlert
					onClose={handleSnackbarClose}
					severity="success"
					sx={{ width: '100%' }}
				>
					Well successfully created.
				</MuiAlert>
			</Snackbar>
		</>
	);
}
