import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { SxProps, Theme } from '@mui/material';
import { RigType } from '@fastsheep/densicalc-core';

type RigTypeSelectorProps = {
	value: RigType;
	onChange: (value: RigType) => void;
	sx?: SxProps<Theme>;
	id?: string;
	label?: string;
	size?: 'small' | 'medium' | undefined;
	disabled?: boolean;
};
export default function RigTypeSelector(props: RigTypeSelectorProps) {
	const onChange = (
		event: SelectChangeEvent<RigType>,
		child: React.ReactNode
	) => {
		props.onChange(event.target.value as RigType);
	};

	return (
		<FormControl sx={props.sx}>
			<InputLabel id="rig_type_label">
				{props.label ? props.label : ''}
			</InputLabel>
			<Select
				labelId="rig_type_label"
				size={props.size ? props.size : 'small'}
				label={props.label ? props.label : ''}
				name={props.id ? props.id : 'rig_type'}
				id={props.id ? props.id : 'rig_type'}
				data-cy={props.id ? props.id : 'length_unit'}
				value={props.value}
				onChange={onChange}
				disabled={props.disabled ? props.disabled : false}
			>
				<MenuItem value="sea" id="offshore" data-cy="offshore">
					Offshore
				</MenuItem>
				<MenuItem value="land" id="onshore" data-cy="onshore">
					Onshore
				</MenuItem>
			</Select>
		</FormControl>
	);
}
