import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FluidDensityTempCorrection from '../../components/FluidDensityTempCorrection';
import Header from '../../components/Header';
import WellData from '../../components/WellData';
import WellTabs from '../../components/WellTabs';
import PrintButton from '../../components/PrintButton';
import WellOptions from '../../components/WellOptions';
import SettingsButton from '../../components/SettingsButton';
import SettingsModal from '../../components/SettingsModal';

export default function DensiCalc() {
	const [settingsDialogVisible, setSettingsDialogVisible] =
		useState<boolean>(false);

	return (
		<>
			<Grid container spacing={2} justifyContent="space-evenly">
				<Grid item sm={12} md={12} lg={12}>
					<Header />
				</Grid>
				<Grid item sm={12} md={2} lg={1}>
					<WellTabs />
				</Grid>
				<Grid
					item
					sm={12}
					md={10}
					lg={5}
					justifyContent="center"
					marginBottom="1vw"
					paddingRight={{
						md: '20vw',
						lg: 0
					}}
				>
					<FluidDensityTempCorrection />
				</Grid>
				<Box display={{ lg: 'none', sm: 'none' }}>
					<Grid item md={2} display="flex"></Grid>
				</Box>
				<Grid
					item
					sm={12}
					md={10}
					lg={5}
					justifyContent={{
						md: 'center',
						lg: 'center',
						sm: 'center'
					}}
					marginLeft={{ lg: '2vw', md: '17vw' }}
					marginBottom="1vw"
					paddingRight={{
						md: '20vw',
						lg: 0
					}}
				>
					<WellData />
				</Grid>
			</Grid>

			<PrintButton />
			<WellOptions />
			<SettingsButton onClick={() => setSettingsDialogVisible(true)} />
			<SettingsModal
				open={settingsDialogVisible}
				onClose={() => setSettingsDialogVisible(false)}
			/>
		</>
	);
}
