import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { RigType } from '@fastsheep/densicalc-core';
import {
	findDensityUnitDisplayName,
	findLengthUnitDisplayName,
	findPressureUnitDisplayName,
	findTempUnitDisplayName
} from '../../utils/UnitUtils';
import Calculator from '../../model/Calculator';
import { DEFAULT_SIGNIFICANT_FIGURES } from '../../utils/NumberUtils';
import { useSearchParams } from 'react-router-dom';
import { DensiCalcData } from '../../model/DensiCalcData';
import BannerDesktop from '../../assets/img/DensiCalc Banner with web address.png';
import { Well } from '../../model/Well';

type TdProps = {
	children: React.ReactNode;
};
const Td = ({ children }: TdProps) => {
	return (
		<td>
			<Typography>{children}</Typography>
		</td>
	);
};

export default function Print() {
	const [searchParams] = useSearchParams();

	const densiCalcDataParam = searchParams.get('densiCalcData');
	const densiCalcData = densiCalcDataParam ? atob(densiCalcDataParam) : null;
	const densiCalcDataObj = densiCalcData
		? (JSON.parse(densiCalcData) as DensiCalcData)
		: null;

	const wellParam = searchParams.get('well');
	const well = wellParam ? atob(wellParam) : null;
	const wellObj = well ? (JSON.parse(well) as Well) : null;

	if (densiCalcDataObj === null || wellObj === null) return null;

	const {
		density,
		densityUnit,
		measuredAtTemp,
		measuredAtTempUnit,
		referenceTemperature,
		referenceTemperatureUnit,
		resultDensity,
		resultDensityUnit,
		densityAtRefTemp,
		densityAtRefTempUnit,
		rigType,
		airgap,
		airgapUnit,
		rkbToFlowline,
		rkbToFlowlineUnit,
		waterDepth,
		waterDepthUnit,
		surfaceTemperature,
		surfaceTemperatureUnit,
		seabedTemperature,
		seabedTemperatureUnit,
		downholeTemperature,
		downholeTemperatureUnit,
		downholeTempMeasuredAtDepth,
		downholeTempMeasuredAtDepthUnit,
		analysisPoint,
		analysisPointUnit,
		averageRiserDensity,
		averageRiserDensityUnit,
		fluidPressureAtSeabed,
		fluidPressureAtSeabedUnit,
		riserMargin,
		riserMarginUnit,
		avgDensityAboveTvd,
		avgDensityAboveTvdUnit,
		pressureAtTvd,
		pressureAtTvdUnit,
		temperatureAtTvd,
		temperatureAtTvdUnit
	} = densiCalcDataObj;

	const getRigTypeDescription = (_rigType: RigType): string => {
		if (_rigType === 'sea') return 'Offshore';
		if (_rigType === 'land') return 'Onshore';

		return '';
	};

	const onPrintClick = () => {
		const printButton = document.getElementById(
			'print_page_buttons_container'
		);
		if (printButton !== null) {
			const previousDisplay = printButton?.style.display;
			printButton.style.display = 'none';
			window.print();
			printButton.style.display = previousDisplay;
		}
	};

	const onCalculatorClick = () => {
		const url = window.origin + '/';
		window.open(url, '_blank');
	};

	return (
		<Stack direction="row">
			<Box
				sx={{
					'table, th, td': {
						padding: '8px',
						margin: '0px'
					},
					table: {
						borderCollapse: 'collapse'
					}
				}}
			>
				<div
					style={{
						width: '21cm',
						height: '29.7cm',
						padding: '0.5cm'
					}}
				>
					<div
						style={{
							width: '100%',
							display: 'flex'
						}}
					>
						<img
							alt="DensiCalc Banner"
							src={BannerDesktop}
							width="100%"
						/>
					</div>
					<table>
						<caption>
							<Typography variant="h4">
								{wellObj.description}
							</Typography>
						</caption>
						<tr>
							<th colSpan={3}>
								Fluid density temperature correction
							</th>
							<th style={{ width: '30px' }}></th>
							<th colSpan={3}>Well data</th>
						</tr>
						<tr>
							<Td>Fluid density...</Td>
							<Td>{density}</Td>
							<Td>{findDensityUnitDisplayName(densityUnit)}</Td>

							<td></td>

							<Td>Offshore/Onshore</Td>
							<Td>{getRigTypeDescription(rigType)}</Td>
						</tr>
						<tr>
							<Td>...measured at</Td>
							<Td>{measuredAtTemp}</Td>
							<Td>
								{findTempUnitDisplayName(measuredAtTempUnit)}
							</Td>

							<td></td>

							<Td>Airgap (RKB to MSL)</Td>
							<Td>{airgap}</Td>
							<Td>{findLengthUnitDisplayName(airgapUnit)}</Td>
						</tr>
						<tr>
							<Td>Density at 15.6°C / 60°F</Td>
							<Td>
								{Calculator.handleOutput(
									resultDensity,
									resultDensityUnit,
									DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findDensityUnitDisplayName(resultDensityUnit)}
							</Td>

							<td></td>

							<Td>RKB to flowline</Td>
							<Td>{rkbToFlowline}</Td>
							<Td>
								{findLengthUnitDisplayName(rkbToFlowlineUnit)}
							</Td>
						</tr>

						<tr>
							<Td>Reference temperature</Td>
							<Td>{referenceTemperature}</Td>
							<Td>
								{findTempUnitDisplayName(
									referenceTemperatureUnit
								)}
							</Td>

							<td></td>

							<Td>Water depth</Td>
							<Td>{waterDepth}</Td>
							<Td>{findLengthUnitDisplayName(waterDepthUnit)}</Td>
						</tr>

						<tr>
							<Td>{`Density at ${referenceTemperature}${findTempUnitDisplayName(
								referenceTemperatureUnit
							)}`}</Td>
							<Td>
								{Calculator.handleOutput(
									densityAtRefTemp,
									densityAtRefTempUnit,
									DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findDensityUnitDisplayName(
									densityAtRefTempUnit
								)}
							</Td>

							<td></td>

							<Td>Surface temperature</Td>
							<Td>{surfaceTemperature}</Td>
							<Td>
								{findTempUnitDisplayName(
									surfaceTemperatureUnit
								)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>Seabed temperature</Td>
							<Td>{seabedTemperature}</Td>
							<Td>
								{findTempUnitDisplayName(seabedTemperatureUnit)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>Downhole temperature...</Td>
							<Td>{downholeTemperature}</Td>
							<Td>
								{findTempUnitDisplayName(
									downholeTemperatureUnit
								)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>...measured at</Td>
							<Td>{downholeTempMeasuredAtDepth}</Td>
							<Td>
								{findLengthUnitDisplayName(
									downholeTempMeasuredAtDepthUnit
								)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>Analysis point(TVD)</Td>
							<Td>{analysisPoint}</Td>
							<Td>
								{findLengthUnitDisplayName(analysisPointUnit)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>Average riser density</Td>
							<Td>
								{Calculator.handleOutput(
									averageRiserDensity,
									averageRiserDensityUnit,
									DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findDensityUnitDisplayName(
									averageRiserDensityUnit
								)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>Fluid pressure at seabed</Td>
							<Td>
								{Calculator.handleOutput(
									fluidPressureAtSeabed,
									fluidPressureAtSeabedUnit,
									DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findPressureUnitDisplayName(
									fluidPressureAtSeabedUnit
								)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>Riser margin</Td>
							<Td>
								{Calculator.handleOutput(
									riserMargin,
									riserMarginUnit,
									DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findDensityUnitDisplayName(riserMarginUnit)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>Avg. density above TVD</Td>
							<Td>
								{Calculator.handleOutput(
									avgDensityAboveTvd,
									avgDensityAboveTvdUnit,
									DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findDensityUnitDisplayName(
									avgDensityAboveTvdUnit
								)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>{`Pressure at ${analysisPoint}`}</Td>
							<Td>
								{Calculator.handleOutput(
									pressureAtTvd,
									pressureAtTvdUnit,
									5 //DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findPressureUnitDisplayName(pressureAtTvdUnit)}
							</Td>
						</tr>

						<tr>
							<td colSpan={3}></td>

							<td></td>

							<Td>{`Temperature at ${analysisPoint}`}</Td>
							<Td>
								{Calculator.handleOutput(
									temperatureAtTvd,
									temperatureAtTvdUnit,
									3 //DEFAULT_SIGNIFICANT_FIGURES
								)}
							</Td>
							<Td>
								{findTempUnitDisplayName(temperatureAtTvdUnit)}
							</Td>
						</tr>
					</table>
				</div>
			</Box>
			<div
				id="print_page_buttons_container"
				style={{
					display: 'flex',
					flexFlow: 'column',
					justifyContent: 'flex-start',
					// width: '297mm',
					// backgroundColor: 'purple',
					marginLeft: '3%'
				}}
			>
				<Button
					id="print_page_print_button"
					variant="contained"
					sx={{
						backgroundColor: '#164194',
						marginTop: 5,
						width: 120
					}}
					onClick={onPrintClick}
				>
					Print
				</Button>

				<Button
					id="print_page_calculator_button"
					variant="contained"
					sx={{
						backgroundColor: '#E0E0E0',
						color: 'rgba(0, 0, 0, 0.87)',
						marginRight: 2,
						marginTop: 5,
						width: 120
					}}
					onClick={onCalculatorClick}
				>
					Calculator
				</Button>
			</div>
		</Stack>
	);
}
