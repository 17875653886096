import { useEffect, useContext } from 'react';
import { getEmptyDensiCalcData } from '../model/DensiCalcData';
import { DensiCalcContext } from '../providers/DensiCalcProvider';
import { WellContext } from '../providers/WellProvider';
import DensiCalcDataRepository from '../repositories/DensiCalcDataRepository';

export function useLoadDensiCalcData() {
	const {
		setDensity,
		setDensityUnit,
		setMeasuredAtTemp,
		setMeasuredAtTempUnit,
		setReferenceTemperature,
		setReferenceTemperatureUnit,
		setResultDensity,
		setResultDensityUnit,
		setDensityAtRefTemp,
		setDensityAtRefTempUnit,
		setRigType,
		setAirgap,
		setAirgapUnit,
		setRkbToFlowline,
		setRkbToFlowlineUnit,
		setWaterDepth,
		setWaterDepthUnit,
		setSurfaceTemperature,
		setSurfaceTemperatureUnit,
		setSeabedTemperature,
		setSeabedTemperatureUnit,
		setDownholeTemperature,
		setDownholeTemperatureUnit,
		setDownholeTempMeasuredAtDepth,
		setDownholeTempMeasuredAtDepthUnit,
		setAnalysisPoint,
		setAnalysisPointUnit,
		setAverageRiserDensity,
		setAverageRiserDensityUnit,
		setFluidPressureAtSeabed,
		setFluidPressureAtSeabedUnit,
		setRiserMargin,
		setRiserMarginUnit,
		setAvgDensityAboveTvd,
		setAvgDensityAboveTvdUnit,
		setPressureAtTvd,
		setPressureAtTvdUnit,
		setTemperatureAtTvd,
		setTemperatureAtTvdUnit
	} = useContext(DensiCalcContext);

	const { selectedWell } = useContext(WellContext);

	useEffect(() => {
		const data = DensiCalcDataRepository.findDensiCalcData(selectedWell.id);
		if (data !== null) {
			const {
				density,
				densityUnit,
				measuredAtTemp,
				measuredAtTempUnit,
				referenceTemperature,
				referenceTemperatureUnit,
				resultDensity,
				resultDensityUnit,
				densityAtRefTemp,
				densityAtRefTempUnit,
				rigType,
				airgap,
				airgapUnit,
				rkbToFlowline,
				rkbToFlowlineUnit,
				waterDepth,
				waterDepthUnit,
				surfaceTemperature,
				surfaceTemperatureUnit,
				seabedTemperature,
				seabedTemperatureUnit,
				downholeTemperature,
				downholeTemperatureUnit,
				downholeTempMeasuredAtDepth,
				downholeTempMeasuredAtDepthUnit,
				analysisPoint,
				analysisPointUnit,
				averageRiserDensity,
				averageRiserDensityUnit,
				fluidPressureAtSeabed,
				fluidPressureAtSeabedUnit,
				riserMargin,
				riserMarginUnit,
				avgDensityAboveTvd,
				avgDensityAboveTvdUnit,
				pressureAtTvd,
				pressureAtTvdUnit,
				temperatureAtTvd,
				temperatureAtTvdUnit
			} = data;

			setDensity(density);
			setDensityUnit(densityUnit);
			setMeasuredAtTemp(measuredAtTemp);
			setMeasuredAtTempUnit(measuredAtTempUnit);
			setReferenceTemperature(referenceTemperature);
			setReferenceTemperatureUnit(referenceTemperatureUnit);
			setResultDensity(resultDensity);
			setResultDensityUnit(resultDensityUnit);
			setDensityAtRefTemp(densityAtRefTemp);
			setDensityAtRefTempUnit(densityAtRefTempUnit);
			setRigType(rigType);
			setAirgap(airgap);
			setAirgapUnit(airgapUnit);
			setRkbToFlowline(rkbToFlowline);
			setRkbToFlowlineUnit(rkbToFlowlineUnit);
			setWaterDepth(waterDepth);
			setWaterDepthUnit(waterDepthUnit);
			setSurfaceTemperature(surfaceTemperature);
			setSurfaceTemperatureUnit(surfaceTemperatureUnit);
			setSeabedTemperature(seabedTemperature);
			setSeabedTemperatureUnit(seabedTemperatureUnit);
			setDownholeTemperature(downholeTemperature);
			setDownholeTemperatureUnit(downholeTemperatureUnit);
			setDownholeTempMeasuredAtDepth(downholeTempMeasuredAtDepth);
			setDownholeTempMeasuredAtDepthUnit(downholeTempMeasuredAtDepthUnit);
			setAnalysisPoint(analysisPoint);
			setAnalysisPointUnit(analysisPointUnit);
			setAverageRiserDensity(averageRiserDensity);
			setAverageRiserDensityUnit(averageRiserDensityUnit);
			setFluidPressureAtSeabed(fluidPressureAtSeabed);
			setFluidPressureAtSeabedUnit(fluidPressureAtSeabedUnit);
			setRiserMargin(riserMargin);
			setRiserMarginUnit(riserMarginUnit);
			setAvgDensityAboveTvd(avgDensityAboveTvd);
			setAvgDensityAboveTvdUnit(avgDensityAboveTvdUnit);
			setPressureAtTvd(pressureAtTvd);
			setPressureAtTvdUnit(pressureAtTvdUnit);
			setTemperatureAtTvd(temperatureAtTvd);
			setTemperatureAtTvdUnit(temperatureAtTvdUnit);
		} else {
			const {
				density,
				densityUnit,
				measuredAtTemp,
				measuredAtTempUnit,
				referenceTemperature,
				referenceTemperatureUnit,
				resultDensity,
				resultDensityUnit,
				densityAtRefTemp,
				densityAtRefTempUnit,
				rigType,
				airgap,
				airgapUnit,
				rkbToFlowline,
				rkbToFlowlineUnit,
				waterDepth,
				waterDepthUnit,
				surfaceTemperature,
				surfaceTemperatureUnit,
				seabedTemperature,
				seabedTemperatureUnit,
				downholeTemperature,
				downholeTemperatureUnit,
				downholeTempMeasuredAtDepth,
				downholeTempMeasuredAtDepthUnit,
				analysisPoint,
				analysisPointUnit,
				averageRiserDensity,
				averageRiserDensityUnit,
				fluidPressureAtSeabed,
				fluidPressureAtSeabedUnit,
				riserMargin,
				riserMarginUnit,
				avgDensityAboveTvd,
				avgDensityAboveTvdUnit,
				pressureAtTvd,
				pressureAtTvdUnit,
				temperatureAtTvd,
				temperatureAtTvdUnit
			} = getEmptyDensiCalcData();

			setDensity(density);
			setDensityUnit(densityUnit);
			setMeasuredAtTemp(measuredAtTemp);
			setMeasuredAtTempUnit(measuredAtTempUnit);
			setReferenceTemperature(referenceTemperature);
			setReferenceTemperatureUnit(referenceTemperatureUnit);
			setResultDensity(resultDensity);
			setResultDensityUnit(resultDensityUnit);
			setDensityAtRefTemp(densityAtRefTemp);
			setDensityAtRefTempUnit(densityAtRefTempUnit);
			setRigType(rigType);
			setAirgap(airgap);
			setAirgapUnit(airgapUnit);
			setRkbToFlowline(rkbToFlowline);
			setRkbToFlowlineUnit(rkbToFlowlineUnit);
			setWaterDepth(waterDepth);
			setWaterDepthUnit(waterDepthUnit);
			setSurfaceTemperature(surfaceTemperature);
			setSurfaceTemperatureUnit(surfaceTemperatureUnit);
			setSeabedTemperature(seabedTemperature);
			setSeabedTemperatureUnit(seabedTemperatureUnit);
			setDownholeTemperature(downholeTemperature);
			setDownholeTemperatureUnit(downholeTemperatureUnit);
			setDownholeTempMeasuredAtDepth(downholeTempMeasuredAtDepth);
			setDownholeTempMeasuredAtDepthUnit(downholeTempMeasuredAtDepthUnit);
			setAnalysisPoint(analysisPoint);
			setAnalysisPointUnit(analysisPointUnit);
			setAverageRiserDensity(averageRiserDensity);
			setAverageRiserDensityUnit(averageRiserDensityUnit);
			setFluidPressureAtSeabed(fluidPressureAtSeabed);
			setFluidPressureAtSeabedUnit(fluidPressureAtSeabedUnit);
			setRiserMargin(riserMargin);
			setRiserMarginUnit(riserMarginUnit);
			setAvgDensityAboveTvd(avgDensityAboveTvd);
			setAvgDensityAboveTvdUnit(avgDensityAboveTvdUnit);
			setPressureAtTvd(pressureAtTvd);
			setPressureAtTvdUnit(pressureAtTvdUnit);
			setTemperatureAtTvd(temperatureAtTvd);
			setTemperatureAtTvdUnit(temperatureAtTvdUnit);
		}
	}, [selectedWell]);
}
