import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { useContext, useState } from 'react';
import { WellContext } from '../../../../providers/WellProvider';
import { Well } from '../../../../model/Well';

export type WellSelectorModalProps = {
	open: boolean;
	onClose: () => void;
	onConfirm: (wells: Well[]) => void;
};
export default function WellSelectorModal({
	open,
	onClose,
	onConfirm
}: WellSelectorModalProps) {
	const { wells } = useContext(WellContext);
	const [selected, setSelected] = useState<Well[]>([]);

	const handleOnChange = (value: boolean, well: Well) => {
		if (value) {
			setSelected((prev) => [...prev, well]);
		} else {
			setSelected((prev) => prev.filter((e) => e.id !== well.id));
		}
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>Select wells to export</DialogTitle>
			<DialogContent>
				{wells.map((w, i) => (
					<Stack direction="row" alignItems="center" key={i}>
						<Checkbox
							checked={
								selected.filter((e) => e.id === w.id).length > 0
							}
							onChange={(e) =>
								handleOnChange(!!e.target.checked, w)
							}
						/>
						<Typography>{w.description}</Typography>
					</Stack>
				))}
			</DialogContent>
			<DialogActions>
				<Stack direction="row" justifyContent="space-between">
					<Button color="error" onClick={onClose}>
						Cancel
					</Button>
					<Button
						onClick={() => {
							onConfirm(selected);
							onClose();
						}}
					>
						Export
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
}
