import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {
	DensiCalcOptions,
	getDefaultDensiCalcOptions
} from '../../model/Options';
import DensiCalcOptionsRepository from '../../repositories/DensiCalcOptionsRepository';

export default function ABTests() {
	let savedOptions = DensiCalcOptionsRepository.getDensiCalcOptions();
	if (savedOptions === null) savedOptions = getDefaultDensiCalcOptions();
	const [densiCalcOptions, setDensiCalcOptions] =
		useState<DensiCalcOptions>(savedOptions);

	return (
		<Box margin={10}>
			<Stack direction="column">
				<Typography variant="h3">
					Siv, this page is for you only
				</Typography>
				<Typography sx={{ marginTop: '20px' }}>
					The point of this page is to provide you with options that
					change the looks/behaviour of the DensiCalc app.
				</Typography>
				<Typography>
					You can check/uncheck the options below. Then you click on
					save and reload the DensiCalc app to see the changes.
				</Typography>
				<Typography>
					Those changes will be available only for your local session
					though.
				</Typography>
				<Typography>
					So once you are satisfied with a set of options you can send
					me a print of the options you selected and I will add them
					as "default" in the final version.
				</Typography>
				<Box sx={{ marginTop: '20px' }}>
					<Typography variant="h4">Options</Typography>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={densiCalcOptions.groupedButtons}
									onChange={() =>
										setDensiCalcOptions({
											...densiCalcOptions,
											groupedButtons:
												!densiCalcOptions.groupedButtons
										})
									}
								/>
							}
							label="Well options: Grouped buttons"
						/>
					</FormGroup>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={
										densiCalcOptions.coloredOutputSelectors
									}
									onChange={() =>
										setDensiCalcOptions({
											...densiCalcOptions,
											coloredOutputSelectors:
												!densiCalcOptions.coloredOutputSelectors
										})
									}
								/>
							}
							label="Colored background for output unit selectors"
						/>
					</FormGroup>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={
										densiCalcOptions.spaceBetweenFieldAndSelectors
									}
									onChange={() =>
										setDensiCalcOptions({
											...densiCalcOptions,
											spaceBetweenFieldAndSelectors:
												!densiCalcOptions.spaceBetweenFieldAndSelectors
										})
									}
								/>
							}
							label="Space between field and unit selector"
						/>
					</FormGroup>
				</Box>
				<Button
					variant="contained"
					onClick={() => {
						try {
							DensiCalcOptionsRepository.saveDensiCalcOptions(
								densiCalcOptions
							);
							alert('Successfully saved.');
						} catch (error) {
							alert('Error saving: ' + error);
						}
					}}
				>
					Save
				</Button>
			</Stack>
		</Box>
	);
}
