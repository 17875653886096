import {
	DensityUnit,
	DENSITY_UNITS,
	LengthUnit,
	LENGTH_UNITS,
	PressureUnit,
	PRESSURE_UNITS,
	TemperatureUnit,
	TEMP_UNITS
} from '@fastsheep/densicalc-core';

export const findTempUnitDisplayName = (unit: TemperatureUnit): string =>
	TEMP_UNITS.filter((e) => e.code === unit)[0].displayName;

export const findDensityUnitDisplayName = (unit: DensityUnit): string =>
	DENSITY_UNITS.filter((e) => e.code === unit)[0].displayName;

export const findLengthUnitDisplayName = (unit: LengthUnit): string =>
	LENGTH_UNITS.filter((e) => e.code === unit)[0].displayName;

export const findPressureUnitDisplayName = (unit: PressureUnit): string =>
	PRESSURE_UNITS.filter((e) => e.code === unit)[0].displayName;

export type DefaultUnits = {
	LENGTH: LengthUnit;
	DENSITY: DensityUnit;
	PRESSURE: PressureUnit;
	TEMPERATURE: TemperatureUnit;
};
export const DEFAULT_UNITS: DefaultUnits = {
	LENGTH: 'm',
	DENSITY: 'gcm',
	PRESSURE: 'psi',
	TEMPERATURE: 'c'
};
