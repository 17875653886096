export type DensiCalcOptions = {
	groupedButtons: boolean;
	coloredOutputSelectors: boolean;
	spaceBetweenFieldAndSelectors: boolean;
};

export const getDefaultDensiCalcOptions = (): DensiCalcOptions => {
	return {
		groupedButtons: true,
		coloredOutputSelectors: false,
		spaceBetweenFieldAndSelectors: false
	};
};
