import { DensiCalcOptions } from '../model/Options';

export const DENSI_CALC_OPTIONS_LOCAL_STORAGE_KEY = 'DENSI_CALC_OPTIONS';

type getDensiCalcOptionsReturn = DensiCalcOptions | null;

export class DensiCalcOptionsRepository {
	saveDensiCalcOptions(densiCalcOptions: DensiCalcOptions) {
		localStorage.setItem(
			DENSI_CALC_OPTIONS_LOCAL_STORAGE_KEY,
			JSON.stringify(densiCalcOptions)
		);
	}

	getDensiCalcOptions(): getDensiCalcOptionsReturn {
		const options = localStorage.getItem(
			DENSI_CALC_OPTIONS_LOCAL_STORAGE_KEY
		);
		if (options) return JSON.parse(options) as DensiCalcOptions;

		return null;
	}
}

export default new DensiCalcOptionsRepository();
