import Stack from '@mui/material/Stack';

type InputRowProps = {
	children: React.ReactNode;
};
export default function InputRow({ children }: InputRowProps) {
	return (
		<Stack
			direction="row"
			spacing={2}
			justifyContent="flex-end"
			sx={{ paddingRight: { md: '10vw', lg: '3vw' } }}
		>
			{children}
		</Stack>
	);
}
