import {
	DensityUnit,
	LengthUnit,
	PressureUnit,
	RigType,
	TemperatureUnit,
	Unit,
	convertToSI
} from '@fastsheep/densicalc-core';
import React, { createContext, useEffect, useState } from 'react';
import Calculator from '../model/Calculator';
import { getEmptyDensiCalcData } from '../model/DensiCalcData';
import DensiCalcDataRepository from '../repositories/DensiCalcDataRepository';
import { convertToNumber } from '../utils/NumberUtils';

interface DensiCalcState {
	density: string;
	densityUnit: DensityUnit;
	measuredAtTemp: string;
	measuredAtTempUnit: TemperatureUnit;
	referenceTemperature: string;
	referenceTemperatureUnit: TemperatureUnit;
	setDensity: React.Dispatch<React.SetStateAction<string>>;
	setDensityUnit: React.Dispatch<React.SetStateAction<DensityUnit>>;
	setMeasuredAtTemp: React.Dispatch<React.SetStateAction<string>>;
	setMeasuredAtTempUnit: React.Dispatch<
		React.SetStateAction<TemperatureUnit>
	>;
	setReferenceTemperature: React.Dispatch<React.SetStateAction<string>>;
	setReferenceTemperatureUnit: React.Dispatch<
		React.SetStateAction<TemperatureUnit>
	>;

	resultDensity: string;
	resultDensityUnit: DensityUnit;
	densityAtRefTemp: string;
	densityAtRefTempUnit: DensityUnit;
	setResultDensity: React.Dispatch<React.SetStateAction<string>>;
	setResultDensityUnit: React.Dispatch<React.SetStateAction<DensityUnit>>;
	setDensityAtRefTemp: React.Dispatch<React.SetStateAction<string>>;
	setDensityAtRefTempUnit: React.Dispatch<React.SetStateAction<DensityUnit>>;

	// Well Data
	rigType: RigType;
	airgap: string;
	airgapUnit: LengthUnit;
	rkbToFlowline: string;
	rkbToFlowlineUnit: LengthUnit;
	waterDepth: string;
	waterDepthUnit: LengthUnit;
	surfaceTemperature: string;
	surfaceTemperatureUnit: TemperatureUnit;
	seabedTemperature: string;
	seabedTemperatureUnit: TemperatureUnit;
	downholeTemperature: string;
	downholeTemperatureUnit: TemperatureUnit;
	downholeTempMeasuredAtDepth: string;
	downholeTempMeasuredAtDepthUnit: LengthUnit;
	analysisPoint: string;
	analysisPointUnit: LengthUnit;
	setRigType: React.Dispatch<React.SetStateAction<RigType>>;
	setAirgap: React.Dispatch<React.SetStateAction<string>>;
	setAirgapUnit: React.Dispatch<React.SetStateAction<LengthUnit>>;
	setRkbToFlowline: React.Dispatch<React.SetStateAction<string>>;
	setRkbToFlowlineUnit: React.Dispatch<React.SetStateAction<LengthUnit>>;
	setWaterDepth: React.Dispatch<React.SetStateAction<string>>;
	setWaterDepthUnit: React.Dispatch<React.SetStateAction<LengthUnit>>;
	setSurfaceTemperature: React.Dispatch<React.SetStateAction<string>>;
	setSurfaceTemperatureUnit: React.Dispatch<
		React.SetStateAction<TemperatureUnit>
	>;
	setSeabedTemperature: React.Dispatch<React.SetStateAction<string>>;
	setSeabedTemperatureUnit: React.Dispatch<
		React.SetStateAction<TemperatureUnit>
	>;
	setDownholeTemperature: React.Dispatch<React.SetStateAction<string>>;
	setDownholeTemperatureUnit: React.Dispatch<
		React.SetStateAction<TemperatureUnit>
	>;
	setDownholeTempMeasuredAtDepth: React.Dispatch<
		React.SetStateAction<string>
	>;
	setDownholeTempMeasuredAtDepthUnit: React.Dispatch<
		React.SetStateAction<LengthUnit>
	>;
	setAnalysisPoint: React.Dispatch<React.SetStateAction<string>>;
	setAnalysisPointUnit: React.Dispatch<React.SetStateAction<LengthUnit>>;

	// Well Data Results
	averageRiserDensity: string;
	averageRiserDensityUnit: DensityUnit;
	fluidPressureAtSeabed: string;
	fluidPressureAtSeabedUnit: PressureUnit;
	riserMargin: string;
	riserMarginUnit: DensityUnit;
	avgDensityAboveTvd: string;
	avgDensityAboveTvdUnit: DensityUnit;
	pressureAtTvd: string;
	pressureAtTvdUnit: PressureUnit;
	temperatureAtTvd: string;
	temperatureAtTvdUnit: TemperatureUnit;
	setAverageRiserDensity: React.Dispatch<React.SetStateAction<string>>;
	setAverageRiserDensityUnit: React.Dispatch<
		React.SetStateAction<DensityUnit>
	>;
	setFluidPressureAtSeabed: React.Dispatch<React.SetStateAction<string>>;
	setFluidPressureAtSeabedUnit: React.Dispatch<
		React.SetStateAction<PressureUnit>
	>;
	setRiserMargin: React.Dispatch<React.SetStateAction<string>>;
	setRiserMarginUnit: React.Dispatch<React.SetStateAction<DensityUnit>>;
	setAvgDensityAboveTvd: React.Dispatch<React.SetStateAction<string>>;
	setAvgDensityAboveTvdUnit: React.Dispatch<
		React.SetStateAction<DensityUnit>
	>;
	setPressureAtTvd: React.Dispatch<React.SetStateAction<string>>;
	setPressureAtTvdUnit: React.Dispatch<React.SetStateAction<PressureUnit>>;
	setTemperatureAtTvd: React.Dispatch<React.SetStateAction<string>>;
	setTemperatureAtTvdUnit: React.Dispatch<
		React.SetStateAction<TemperatureUnit>
	>;
}

const initialValues: DensiCalcState = {
	setDensity: () => {},
	setDensityUnit: () => {},
	setMeasuredAtTemp: () => {},
	setMeasuredAtTempUnit: () => {},
	setReferenceTemperature: () => {},
	setReferenceTemperatureUnit: () => {},
	setResultDensity: () => {},
	setResultDensityUnit: () => {},
	setDensityAtRefTemp: () => {},
	setDensityAtRefTempUnit: () => {},
	setRigType: () => {},
	setAirgap: () => {},
	setAirgapUnit: () => {},
	setRkbToFlowline: () => {},
	setRkbToFlowlineUnit: () => {},
	setWaterDepth: () => {},
	setWaterDepthUnit: () => {},
	setSurfaceTemperature: () => {},
	setSurfaceTemperatureUnit: () => {},
	setSeabedTemperature: () => {},
	setSeabedTemperatureUnit: () => {},
	setDownholeTemperature: () => {},
	setDownholeTemperatureUnit: () => {},
	setDownholeTempMeasuredAtDepth: () => {},
	setDownholeTempMeasuredAtDepthUnit: () => {},
	setAnalysisPoint: () => {},
	setAnalysisPointUnit: () => {},
	setAverageRiserDensity: () => {},
	setAverageRiserDensityUnit: () => {},
	setFluidPressureAtSeabed: () => {},
	setFluidPressureAtSeabedUnit: () => {},
	setRiserMargin: () => {},
	setRiserMarginUnit: () => {},
	setAvgDensityAboveTvd: () => {},
	setAvgDensityAboveTvdUnit: () => {},
	setPressureAtTvd: () => {},
	setPressureAtTvdUnit: () => {},
	setTemperatureAtTvd: () => {},
	setTemperatureAtTvdUnit: () => {},
	...getEmptyDensiCalcData()
};

export const isDensityValid = (
	density: string,
	densityUnit: DensityUnit
): boolean => {
	const n = convertToNumber(density);
	const densityInSI = convertToSI(n, densityUnit as Unit);

	return densityInSI >= 1.01 && densityInSI <= 2.3;
};

export const DensiCalcContext = createContext<DensiCalcState>(initialValues);

interface DensiCalcProviderProps {
	children: React.ReactNode;
}
export const DensiCalcProvider = ({ children }: DensiCalcProviderProps) => {
	let data = DensiCalcDataRepository.findDensiCalcData(-1);
	if (data === null) {
		data = getEmptyDensiCalcData();
	}

	//#region Declarations
	const [density, setDensity] = useState<string>(data.density);
	const [densityUnit, setDensityUnit] = useState<DensityUnit>(
		data.densityUnit
	);

	const [measuredAtTemp, setMeasuredAtTemp] = useState<string>(
		data.measuredAtTemp
	);
	const [measuredAtTempUnit, setMeasuredAtTempUnit] =
		useState<TemperatureUnit>(data.measuredAtTempUnit);

	const [referenceTemperature, setReferenceTemperature] = useState<string>(
		data.referenceTemperature
	);
	const [referenceTemperatureUnit, setReferenceTemperatureUnit] =
		useState<TemperatureUnit>(data.referenceTemperatureUnit);

	const [resultDensity, setResultDensity] = useState<string>(
		data.resultDensity
	);

	const [resultDensityUnit, setResultDensityUnit] = useState<DensityUnit>(
		data.resultDensityUnit
	);

	const [densityAtRefTemp, setDensityAtRefTemp] = useState<string>(
		data.densityAtRefTemp
	);
	const [densityAtRefTempUnit, setDensityAtRefTempUnit] =
		useState<DensityUnit>(data.densityAtRefTempUnit);

	// Well Data
	const [rigType, setRigType] = useState<RigType>(data.rigType);

	const [airgap, setAirgap] = useState<string>(data.airgap);
	const [airgapUnit, setAirgapUnit] = useState<LengthUnit>(data.airgapUnit);

	const [rkbToFlowline, setRkbToFlowline] = useState<string>(
		data.rkbToFlowline
	);
	const [rkbToFlowlineUnit, setRkbToFlowlineUnit] = useState<LengthUnit>(
		data.rkbToFlowlineUnit
	);

	const [waterDepth, setWaterDepth] = useState<string>(data.waterDepth);
	const [waterDepthUnit, setWaterDepthUnit] = useState<LengthUnit>(
		data.waterDepthUnit
	);

	const [surfaceTemperature, setSurfaceTemperature] = useState<string>(
		data.surfaceTemperature
	);
	const [surfaceTemperatureUnit, setSurfaceTemperatureUnit] =
		useState<TemperatureUnit>(data.surfaceTemperatureUnit);

	const [seabedTemperature, setSeabedTemperature] = useState<string>(
		data.seabedTemperature
	);
	const [seabedTemperatureUnit, setSeabedTemperatureUnit] =
		useState<TemperatureUnit>(data.seabedTemperatureUnit);

	const [downholeTemperature, setDownholeTemperature] = useState<string>(
		data.downholeTemperature
	);
	const [downholeTemperatureUnit, setDownholeTemperatureUnit] =
		useState<TemperatureUnit>(data.downholeTemperatureUnit);

	const [downholeTempMeasuredAtDepth, setDownholeTempMeasuredAtDepth] =
		useState<string>(data.downholeTempMeasuredAtDepth);
	const [
		downholeTempMeasuredAtDepthUnit,
		setDownholeTempMeasuredAtDepthUnit
	] = useState<LengthUnit>(data.downholeTempMeasuredAtDepthUnit);

	const [analysisPoint, setAnalysisPoint] = useState<string>(
		data.analysisPoint
	);
	const [analysisPointUnit, setAnalysisPointUnit] = useState<LengthUnit>(
		data.analysisPointUnit
	);

	const [averageRiserDensity, setAverageRiserDensity] = useState<string>(
		data.averageRiserDensity
	);
	const [averageRiserDensityUnit, setAverageRiserDensityUnit] =
		useState<DensityUnit>(data.averageRiserDensityUnit);

	const [fluidPressureAtSeabed, setFluidPressureAtSeabed] = useState<string>(
		data.fluidPressureAtSeabed
	);
	const [fluidPressureAtSeabedUnit, setFluidPressureAtSeabedUnit] =
		useState<PressureUnit>(data.fluidPressureAtSeabedUnit);

	const [riserMargin, setRiserMargin] = useState<string>(data.riserMargin);
	const [riserMarginUnit, setRiserMarginUnit] = useState<DensityUnit>(
		data.riserMarginUnit
	);

	const [avgDensityAboveTvd, setAvgDensityAboveTvd] = useState<string>(
		data.avgDensityAboveTvd
	);
	const [avgDensityAboveTvdUnit, setAvgDensityAboveTvdUnit] =
		useState<DensityUnit>(data.avgDensityAboveTvdUnit);

	const [pressureAtTvd, setPressureAtTvd] = useState<string>(
		data.pressureAtTvd
	);
	const [pressureAtTvdUnit, setPressureAtTvdUnit] = useState<PressureUnit>(
		data.pressureAtTvdUnit
	);

	const [temperatureAtTvd, setTemperatureAtTvd] = useState<string>(
		data.temperatureAtTvd
	);
	const [temperatureAtTvdUnit, setTemperatureAtTvdUnit] =
		useState<TemperatureUnit>(data.temperatureAtTvdUnit);

	//#endregion

	const values: DensiCalcState = {
		density,
		densityUnit,
		measuredAtTemp,
		measuredAtTempUnit,
		referenceTemperature,
		referenceTemperatureUnit,
		setDensity,
		setDensityUnit,
		setMeasuredAtTemp,
		setMeasuredAtTempUnit,
		setReferenceTemperature,
		setReferenceTemperatureUnit,

		resultDensity,
		resultDensityUnit,
		densityAtRefTemp,
		densityAtRefTempUnit,
		setResultDensity,
		setResultDensityUnit,
		setDensityAtRefTemp,
		setDensityAtRefTempUnit,

		// Well Data
		rigType,
		airgap,
		airgapUnit,
		rkbToFlowline,
		rkbToFlowlineUnit,
		waterDepth,
		waterDepthUnit,
		surfaceTemperature,
		surfaceTemperatureUnit,
		seabedTemperature,
		seabedTemperatureUnit,
		downholeTemperature,
		downholeTemperatureUnit,
		downholeTempMeasuredAtDepth,
		downholeTempMeasuredAtDepthUnit,
		analysisPoint,
		analysisPointUnit,
		setRigType,
		setAirgap,
		setAirgapUnit,
		setRkbToFlowline,
		setRkbToFlowlineUnit,
		setWaterDepth,
		setWaterDepthUnit,
		setSurfaceTemperature,
		setSurfaceTemperatureUnit,
		setSeabedTemperature,
		setSeabedTemperatureUnit,
		setDownholeTemperature,
		setDownholeTemperatureUnit,
		setDownholeTempMeasuredAtDepth,
		setDownholeTempMeasuredAtDepthUnit,
		setAnalysisPoint,
		setAnalysisPointUnit,

		// Well Data Result
		averageRiserDensity,
		averageRiserDensityUnit,
		fluidPressureAtSeabed,
		fluidPressureAtSeabedUnit,
		riserMargin,
		riserMarginUnit,
		avgDensityAboveTvd,
		avgDensityAboveTvdUnit,
		pressureAtTvd,
		pressureAtTvdUnit,
		temperatureAtTvd,
		temperatureAtTvdUnit,
		setAverageRiserDensity,
		setAverageRiserDensityUnit,
		setFluidPressureAtSeabed,
		setFluidPressureAtSeabedUnit,
		setRiserMargin,
		setRiserMarginUnit,
		setAvgDensityAboveTvd,
		setAvgDensityAboveTvdUnit,
		setPressureAtTvd,
		setPressureAtTvdUnit,
		setTemperatureAtTvd,
		setTemperatureAtTvdUnit
	};

	//#region Effects
	// Effect to execute the calculation of the Fluid density temperature correction.
	useEffect(() => {
		if (
			densityUnit.length > 0 &&
			measuredAtTemp.length > 0 &&
			isDensityValid(density, densityUnit)
		) {
			try {
				const correctDensity = Calculator.calculateDensity(
					Calculator.handleInput(density, densityUnit),
					Calculator.handleInput(measuredAtTemp, measuredAtTempUnit)
				);

				setResultDensity(correctDensity);
			} catch (error) {
				console.log(
					'Error running Calculator.calculateDensity: ',
					error
				);
			}
		} else {
			setResultDensity('');
		}

		if (
			densityUnit.length > 0 &&
			measuredAtTemp.length > 0 &&
			referenceTemperature.length > 0 &&
			isDensityValid(density, densityUnit)
		) {
			try {
				const referenceDensity = Calculator.densityAnalysis(
					Calculator.handleInput(density, densityUnit),
					Calculator.handleInput(measuredAtTemp, measuredAtTempUnit),
					Calculator.handleInput(
						referenceTemperature,
						referenceTemperatureUnit
					)
				);

				setDensityAtRefTemp(referenceDensity);
			} catch (error) {
				console.log(
					'Error running Calculator.densityAnalysis: ',
					error
				);
			}
		} else {
			setDensityAtRefTemp('');
		}
	}, [
		density,
		densityUnit,
		measuredAtTemp,
		measuredAtTempUnit,
		referenceTemperature,
		referenceTemperatureUnit
	]);

	// Effect to execute the calculation of the Well data.
	useEffect(() => {
		if (
			(resultDensity.length > 0 &&
				rigType === 'sea' &&
				airgap.length > 0 &&
				rkbToFlowline.length > 0 &&
				waterDepth.length > 0 &&
				surfaceTemperature.length > 0 &&
				seabedTemperature.length > 0 &&
				downholeTemperature.length > 0 &&
				downholeTempMeasuredAtDepth.length > 0 &&
				analysisPoint.length > 0) ||
			(resultDensity.length > 0 &&
				rigType === 'land' &&
				rkbToFlowline.length > 0 &&
				surfaceTemperature.length > 0 &&
				downholeTemperature.length > 0 &&
				downholeTempMeasuredAtDepth.length > 0 &&
				analysisPoint.length > 0)
		) {
			const { riserDensity, riserDensityStr } =
				Calculator.calcRiserDensity(
					resultDensity,
					Calculator.handleInput(
						seabedTemperature,
						seabedTemperatureUnit
					),
					Calculator.handleInput(
						surfaceTemperature,
						surfaceTemperatureUnit
					),
					Calculator.handleInput(airgap, airgapUnit),
					Calculator.handleInput(waterDepth, waterDepthUnit),
					Calculator.handleInput(rkbToFlowline, rkbToFlowlineUnit)
				);

			const riserEqs = Calculator.riserEqs(
				resultDensity,
				Calculator.handleInput(
					seabedTemperature,
					seabedTemperatureUnit
				),
				Calculator.handleInput(
					surfaceTemperature,
					surfaceTemperatureUnit
				),
				Calculator.handleInput(airgap, airgapUnit),
				Calculator.handleInput(waterDepth, waterDepthUnit),
				Calculator.handleInput(rkbToFlowline, rkbToFlowlineUnit)
			);
			const { seabedPressure, seabedPressureStr } =
				Calculator.calcSeabedPressure(riserDensity, riserEqs.FCR);

			const tvdTemp = Calculator.calcTvdTemperature(
				rigType,
				Calculator.handleInput(
					surfaceTemperature,
					surfaceTemperatureUnit
				),
				Calculator.handleInput(
					downholeTemperature,
					downholeTemperatureUnit
				),
				Calculator.handleInput(analysisPoint, analysisPointUnit),
				Calculator.handleInput(
					downholeTempMeasuredAtDepth,
					downholeTempMeasuredAtDepthUnit
				),
				Calculator.handleInput(
					seabedTemperature,
					seabedTemperatureUnit
				),
				Calculator.handleInput(airgap, airgapUnit),
				Calculator.handleInput(waterDepth, waterDepthUnit)
			);

			const wellboreEqs = Calculator.wellboreEqs(
				rigType,
				Calculator.handleInput(
					surfaceTemperature,
					surfaceTemperatureUnit
				),
				tvdTemp.tvdTemperature,
				Calculator.handleInput(
					seabedTemperature,
					seabedTemperatureUnit
				),
				Calculator.handleInput(analysisPoint, analysisPointUnit),
				Calculator.handleInput(rkbToFlowline, rkbToFlowlineUnit),
				Calculator.handleInput(airgap, airgapUnit),
				Calculator.handleInput(waterDepth, waterDepthUnit),
				resultDensity,
				seabedPressure
			);

			const { riserMarginStr } = Calculator.calcRiserMargin(
				riserEqs.FCR,
				resultDensity,
				Calculator.handleInput(waterDepth, waterDepthUnit),
				wellboreEqs.FCB
			);

			const { tvdPressure, tvdPressureStr } = Calculator.calcTvdPressure(
				rigType,
				wellboreEqs.FCB,
				wellboreEqs.ESDWS,
				seabedPressure
			);

			const { wellboreDensityStr } = Calculator.calcWellboreDensity(
				tvdPressure,
				wellboreEqs.FCT
			);

			if (rigType === 'sea') {
				setAverageRiserDensity(riserDensityStr);
				setFluidPressureAtSeabed(seabedPressureStr);
				setRiserMargin(riserMarginStr);
			}

			setAvgDensityAboveTvd(wellboreDensityStr);
			setPressureAtTvd(tvdPressureStr);
			setTemperatureAtTvd(tvdTemp.tvdTemperatureStr);
		} else {
			setAverageRiserDensity('');
			setFluidPressureAtSeabed('');
			setRiserMargin('');
			setPressureAtTvd('');
			setTemperatureAtTvd('');
			setAvgDensityAboveTvd('');
		}
	}, [
		rigType,
		resultDensity,
		seabedTemperature,
		surfaceTemperature,
		airgap,
		waterDepth,
		rkbToFlowline,
		downholeTempMeasuredAtDepth,
		analysisPoint,
		fluidPressureAtSeabed,
		temperatureAtTvd,
		setAverageRiserDensity,
		setFluidPressureAtSeabed,
		setRiserMargin,
		setPressureAtTvd,
		setTemperatureAtTvd,
		setAvgDensityAboveTvd,
		resultDensityUnit,
		seabedTemperatureUnit,
		surfaceTemperatureUnit,
		airgapUnit,
		waterDepthUnit,
		rkbToFlowlineUnit,
		downholeTemperatureUnit,
		downholeTempMeasuredAtDepthUnit,
		analysisPointUnit,
		fluidPressureAtSeabedUnit,
		temperatureAtTvdUnit,
		downholeTemperature
	]);
	//#endregion

	return (
		<DensiCalcContext.Provider value={values}>
			{children}
		</DensiCalcContext.Provider>
	);
};
