import { useContext } from 'react';
import Stack from '@mui/material/Stack';
import LengthUnitSelector from '../selectors/LengthUnitSelector';
import TemperatureUnitSelector from '../selectors/TemperatureUnitSelector';
import DensityUnitSelector from '../selectors/DensityUnitSelector';
import PressureUnitSelector from '../selectors/PressureUnitSelector';
import { ThemeContext } from '../../providers/ThemeProvider';
import { DensiCalcContext } from '../../providers/DensiCalcProvider';
import RigTypeSelector from '../selectors/RigTypeSelector';
import Section from '../Section';
import Calculator from '../../model/Calculator';
import {
	DEFAULT_SIGNIFICANT_FIGURES,
	addCommas
} from '../../utils/NumberUtils';
import DensiCalcOptionsRepository from '../../repositories/DensiCalcOptionsRepository';
import { getDefaultDensiCalcOptions } from '../../model/Options';
import { SxProps, Theme } from '@mui/material';
import TextField from '../TextField';
import { findLengthUnitDisplayName } from '../../utils/UnitUtils';
import InputRow from '../InputRow';

export default function WellData() {
	const savedOptions = DensiCalcOptionsRepository.getDensiCalcOptions();
	const options = savedOptions ? savedOptions : getDefaultDensiCalcOptions();

	const {
		// Well Data
		rigType,
		airgap,
		airgapUnit,
		rkbToFlowline,
		rkbToFlowlineUnit,
		waterDepth,
		waterDepthUnit,
		surfaceTemperature,
		surfaceTemperatureUnit,
		seabedTemperature,
		seabedTemperatureUnit,
		downholeTemperature,
		downholeTemperatureUnit,
		downholeTempMeasuredAtDepth,
		downholeTempMeasuredAtDepthUnit,
		analysisPoint,
		analysisPointUnit,
		setRigType,
		setAirgap,
		setAirgapUnit,
		setRkbToFlowline,
		setRkbToFlowlineUnit,
		setWaterDepth,
		setWaterDepthUnit,
		setSurfaceTemperature,
		setSurfaceTemperatureUnit,
		setSeabedTemperature,
		setSeabedTemperatureUnit,
		setDownholeTemperature,
		setDownholeTemperatureUnit,
		setDownholeTempMeasuredAtDepth,
		setDownholeTempMeasuredAtDepthUnit,
		setAnalysisPoint,
		setAnalysisPointUnit,

		// Well Data Result
		averageRiserDensity,
		averageRiserDensityUnit,
		fluidPressureAtSeabed,
		fluidPressureAtSeabedUnit,
		riserMargin,
		riserMarginUnit,
		avgDensityAboveTvd,
		avgDensityAboveTvdUnit,
		pressureAtTvd,
		pressureAtTvdUnit,
		temperatureAtTvd,
		temperatureAtTvdUnit,
		setAverageRiserDensityUnit,
		setFluidPressureAtSeabedUnit,
		setRiserMarginUnit,
		setAvgDensityAboveTvdUnit,
		setPressureAtTvdUnit,
		setTemperatureAtTvdUnit
	} = useContext(DensiCalcContext);

	const { colors } = useContext(ThemeContext);

	const selectorsStyle: SxProps<Theme> = {
		width: '10vh',
		marginLeft: options.spaceBetweenFieldAndSelectors ? '10px' : undefined
	};

	return (
		<Section title="Well data">
			<Stack direction="column" spacing={2}>
				<InputRow>
					<RigTypeSelector
						id="rig_type_selector"
						value={rigType}
						onChange={(value) => setRigType(value)}
						size="small"
						label="Offshore/Onshore"
						sx={{ width: '20vh' }}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="airgap"
						label="Airgap (RKB to MSL)"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={airgap}
						onChange={(event) =>
							setAirgap(addCommas(event.target.value))
						}
						disabled={rigType === 'land'}
					/>
					<LengthUnitSelector
						id="airgap_unit_selector"
						value={airgapUnit}
						onChange={(value) => setAirgapUnit(value)}
						sx={selectorsStyle}
						disabled={rigType === 'land'}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="rkb_to_flowline"
						label="RKB to flowline"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={rkbToFlowline}
						onChange={(event) =>
							setRkbToFlowline(addCommas(event.target.value))
						}
					/>
					<LengthUnitSelector
						id="rkb_to_flowline_unit_selector"
						value={rkbToFlowlineUnit}
						onChange={(value) => setRkbToFlowlineUnit(value)}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="water_depth"
						label="Water depth"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={waterDepth}
						onChange={(event) =>
							setWaterDepth(addCommas(event.target.value))
						}
						disabled={rigType === 'land'}
					/>
					<LengthUnitSelector
						id="water_depth_unit_selector"
						value={waterDepthUnit}
						onChange={(value) => setWaterDepthUnit(value)}
						sx={selectorsStyle}
						disabled={rigType === 'land'}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="surface_temperature"
						label="Surface temperature"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={surfaceTemperature}
						onChange={(event) =>
							setSurfaceTemperature(addCommas(event.target.value))
						}
					/>
					<TemperatureUnitSelector
						id="surface_temperature_unit_selector"
						value={surfaceTemperatureUnit}
						onChange={(value) => setSurfaceTemperatureUnit(value)}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="seabed_temperature"
						label="Seabed temperature"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={seabedTemperature}
						onChange={(event) =>
							setSeabedTemperature(addCommas(event.target.value))
						}
						disabled={rigType === 'land'}
					/>
					<TemperatureUnitSelector
						id="seabed_temperature_unit_selector"
						value={seabedTemperatureUnit}
						onChange={(value) => setSeabedTemperatureUnit(value)}
						sx={selectorsStyle}
						disabled={rigType === 'land'}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="downhole_temperature"
						label="Downhole temperature..."
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={downholeTemperature}
						onChange={(event) =>
							setDownholeTemperature(
								addCommas(event.target.value)
							)
						}
					/>
					<TemperatureUnitSelector
						id="downhole_temperature_unit_selector"
						value={downholeTemperatureUnit}
						onChange={(value) => setDownholeTemperatureUnit(value)}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="downhole_temperature_measured_at_depth"
						label="...measured at"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={downholeTempMeasuredAtDepth}
						onChange={(event) =>
							setDownholeTempMeasuredAtDepth(
								addCommas(event.target.value)
							)
						}
					/>
					<LengthUnitSelector
						id="downhole_temperature_measured_at_depth_unit_selector"
						value={downholeTempMeasuredAtDepthUnit}
						onChange={(value) =>
							setDownholeTempMeasuredAtDepthUnit(value)
						}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="analysis_point"
						label="Analysis point (TVD)"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={analysisPoint}
						onChange={(event) =>
							setAnalysisPoint(addCommas(event.target.value))
						}
					/>
					<LengthUnitSelector
						id="analysis_point_unit_selector"
						value={analysisPointUnit}
						onChange={(value) => setAnalysisPointUnit(value)}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="average_riser_density"
						label="Average riser density"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							averageRiserDensity,
							averageRiserDensityUnit,
							DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
						disabled={rigType === 'land'}
						readOnly
					/>
					<DensityUnitSelector
						id="average_riser_density_unit_selector"
						value={averageRiserDensityUnit}
						onChange={(value) => setAverageRiserDensityUnit(value)}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
						disabled={rigType === 'land'}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="fluid_pressure_at_seabed"
						label="Fluid pressure at seabed"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							fluidPressureAtSeabed,
							fluidPressureAtSeabedUnit,
							DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
						disabled={rigType === 'land'}
						readOnly
					/>
					<PressureUnitSelector
						id="fluid_pressure_at_seabed_unit_selector"
						value={fluidPressureAtSeabedUnit}
						onChange={(value) =>
							setFluidPressureAtSeabedUnit(value)
						}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
						disabled={rigType === 'land'}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="riser_margin"
						label="Riser margin"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							riserMargin,
							riserMarginUnit,
							DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
						disabled={rigType === 'land'}
						readOnly
					/>
					<DensityUnitSelector
						id="riser_margin_unit_selector"
						value={riserMarginUnit}
						onChange={(value) => setRiserMarginUnit(value)}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
						disabled={rigType === 'land'}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="avg_density_above_tvd"
						label="Avg. density above TVD"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							avgDensityAboveTvd,
							avgDensityAboveTvdUnit,
							DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
						readOnly
					/>
					<DensityUnitSelector
						id="avg_density_above_tvd_unit_selector"
						value={avgDensityAboveTvdUnit}
						onChange={(value) => setAvgDensityAboveTvdUnit(value)}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="pressure_at_tvd"
						label={`Pressure at ${analysisPoint} ${findLengthUnitDisplayName(
							analysisPointUnit
						)}`}
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							pressureAtTvd,
							pressureAtTvdUnit,
							4 //DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
						readOnly
					/>
					<PressureUnitSelector
						id="pressure_at_tvd_unit_selector"
						value={pressureAtTvdUnit}
						onChange={(value) => setPressureAtTvdUnit(value)}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="temperature_at_tvd"
						label={`Temperature at ${analysisPoint} ${findLengthUnitDisplayName(
							analysisPointUnit
						)}`}
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							temperatureAtTvd,
							temperatureAtTvdUnit,
							3 //DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
						readOnly
					/>
					<TemperatureUnitSelector
						id="temperature_at_tvd_unit_selector"
						value={temperatureAtTvdUnit}
						onChange={(value) => setTemperatureAtTvdUnit(value)}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
					/>
				</InputRow>
			</Stack>
		</Section>
	);
}
