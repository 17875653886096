import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Section from '../Section';
import ImportExportSettings from './ImportExportSettings';
import DefaultUnitSettings from './DefaultUnitsSettings';

export type SettingsModalProps = {
	open: boolean;
	onClose: () => void;
};
export default function SettingsModal({ open, onClose }: SettingsModalProps) {
	return (
		<>
			<Dialog open={open} onClose={onClose} fullWidth>
				<DialogTitle>Options</DialogTitle>
				<DialogContent>
					<Section title="Import/Export">
						<ImportExportSettings onClose={onClose} />
					</Section>

					<Section title="Default Units">
						<DefaultUnitSettings />
					</Section>
				</DialogContent>
				<DialogActions>
					<Button
						color="error"
						onClick={onClose}
						data-cy="settings_dialog_close_button"
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
