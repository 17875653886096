import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return (
		<MuiAlert
			elevation={0}
			ref={ref}
			variant="filled"
			{...props}
			sx={{ position: 'fixed', bottom: '10px', left: '10px' }}
		/>
	);
});
export default Alert;
