import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

export type SettingsButtonProps = {
	onClick: () => void;
};
export default function SettingsButton({ onClick }: SettingsButtonProps) {
	return (
		<Box
			sx={{
				position: 'fixed',
				right: 10,
				top: 10,
				zIndex: 999
			}}
		>
			<IconButton aria-label="open-settings" onClick={onClick} data-cy="settings_button">
				<SettingsIcon />
			</IconButton>
		</Box>
	);
}
