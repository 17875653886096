import React, { createContext, useState } from 'react';
import { Well } from '../model/Well';

export const DEFAULT_WELL: Well = {
	id: -1,
	description: 'Default Well'
};

interface WellState {
	wells: Array<Well>;
	selectedWell: Well;
	addWell: (well: Well) => void;
	editWell: (well: Well) => void;
	deleteWell: (well: Well) => void;
	selectWell: (well: Well) => void;
	setWells: (wells: Array<Well>) => void;
}

const defaultState: WellState = {
	wells: [],
	selectedWell: DEFAULT_WELL,
	addWell: () => {},
	editWell: () => {},
	deleteWell: () => {},
	selectWell: () => {},
	setWells: () => {}
};

export const WellContext = createContext<WellState>(defaultState);

interface WellProviderProps {
	children: React.ReactNode;
}
export default function WellProvider({ children }: WellProviderProps) {
	const [wells, setWells] = useState<Array<Well>>([DEFAULT_WELL]);
	const [selectedWell, setSelectedWell] = useState<Well>(DEFAULT_WELL);

	const addWell = (well: Well) => {
		setWells((prev) => [...prev, well]);
	};

	const deleteWell = (well: Well) => {
		setWells((prev) => prev.filter((e) => e.id !== well.id));
	};

	const editWell = (well: Well) => {
		setWells((prev) => [...prev.filter((e) => e.id !== well.id), well]);
	};

	const selectWell = (well: Well) => {
		setSelectedWell(well);
	};

	const values: WellState = {
		wells,
		selectedWell,
		addWell,
		editWell,
		deleteWell,
		selectWell,
		setWells: (wells: Array<Well>) => setWells(wells)
	};

	return (
		<WellContext.Provider value={values}>{children}</WellContext.Provider>
	);
}
