import {
	DensityUnit,
	LengthUnit,
	PressureUnit,
	RigType,
	TemperatureUnit
} from '@fastsheep/densicalc-core';
import { DEFAULT_UNITS } from '../utils/UnitUtils';

export type DensiCalcData = {
	density: string;
	densityUnit: DensityUnit;
	measuredAtTemp: string;
	measuredAtTempUnit: TemperatureUnit;
	referenceTemperature: string;
	referenceTemperatureUnit: TemperatureUnit;
	resultDensity: string;
	resultDensityUnit: DensityUnit;
	densityAtRefTemp: string;
	densityAtRefTempUnit: DensityUnit;
	rigType: RigType;
	airgap: string;
	airgapUnit: LengthUnit;
	rkbToFlowline: string;
	rkbToFlowlineUnit: LengthUnit;
	waterDepth: string;
	waterDepthUnit: LengthUnit;
	surfaceTemperature: string;
	surfaceTemperatureUnit: TemperatureUnit;
	seabedTemperature: string;
	seabedTemperatureUnit: TemperatureUnit;
	downholeTemperature: string;
	downholeTemperatureUnit: TemperatureUnit;
	downholeTempMeasuredAtDepth: string;
	downholeTempMeasuredAtDepthUnit: LengthUnit;
	analysisPoint: string;
	analysisPointUnit: LengthUnit;
	averageRiserDensity: string;
	averageRiserDensityUnit: DensityUnit;
	fluidPressureAtSeabed: string;
	fluidPressureAtSeabedUnit: PressureUnit;
	riserMargin: string;
	riserMarginUnit: DensityUnit;
	avgDensityAboveTvd: string;
	avgDensityAboveTvdUnit: DensityUnit;
	pressureAtTvd: string;
	pressureAtTvdUnit: PressureUnit;
	temperatureAtTvd: string;
	temperatureAtTvdUnit: TemperatureUnit;
};

export const getEmptyDensiCalcData = (): DensiCalcData => {
	return {
		density: '',
		densityUnit: DEFAULT_UNITS.DENSITY,
		measuredAtTemp: '',
		measuredAtTempUnit: DEFAULT_UNITS.TEMPERATURE,
		referenceTemperature: '',
		referenceTemperatureUnit: DEFAULT_UNITS.TEMPERATURE,
		resultDensity: '',
		resultDensityUnit: DEFAULT_UNITS.DENSITY,
		densityAtRefTemp: '',
		densityAtRefTempUnit: DEFAULT_UNITS.DENSITY,
		rigType: 'sea',
		airgap: '',
		airgapUnit: DEFAULT_UNITS.LENGTH,
		rkbToFlowline: '',
		rkbToFlowlineUnit: DEFAULT_UNITS.LENGTH,
		waterDepth: '',
		waterDepthUnit: DEFAULT_UNITS.LENGTH,
		surfaceTemperature: '',
		surfaceTemperatureUnit: DEFAULT_UNITS.TEMPERATURE,
		seabedTemperature: '',
		seabedTemperatureUnit: DEFAULT_UNITS.TEMPERATURE,
		downholeTemperature: '',
		downholeTemperatureUnit: DEFAULT_UNITS.TEMPERATURE,
		downholeTempMeasuredAtDepth: '',
		downholeTempMeasuredAtDepthUnit: DEFAULT_UNITS.LENGTH,
		analysisPoint: '',
		analysisPointUnit: DEFAULT_UNITS.LENGTH,
		averageRiserDensity: '',
		averageRiserDensityUnit: DEFAULT_UNITS.DENSITY,
		fluidPressureAtSeabed: '',
		fluidPressureAtSeabedUnit: DEFAULT_UNITS.PRESSURE,
		riserMargin: '',
		riserMarginUnit: DEFAULT_UNITS.DENSITY,
		avgDensityAboveTvd: '',
		avgDensityAboveTvdUnit: DEFAULT_UNITS.DENSITY,
		pressureAtTvd: '',
		pressureAtTvdUnit: DEFAULT_UNITS.PRESSURE,
		temperatureAtTvd: '',
		temperatureAtTvdUnit: DEFAULT_UNITS.TEMPERATURE
	};
};
