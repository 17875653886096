import { DensiCalcProvider } from './providers/DensiCalcProvider';
import ThemeProvider from './providers/ThemeProvider';
import WellProvider from './providers/WellProvider';
import Routes from './routes';

export default function App() {
	return (
		<DensiCalcProvider>
			<ThemeProvider>
				<WellProvider>
					<Routes />
				</WellProvider>
			</ThemeProvider>
		</DensiCalcProvider>
	);
}
