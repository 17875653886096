import { useCallback, useContext } from 'react';
import Fab from '@mui/material/Fab';
import PrintIcon from '@mui/icons-material/Print';
import { ThemeContext } from '../../providers/ThemeProvider';
import { WellContext } from '../../providers/WellProvider';
import DensiCalcDataRepository from '../../repositories/DensiCalcDataRepository';

export default function PrintButton() {
	const { selectedWell } = useContext(WellContext);
	const { colors } = useContext(ThemeContext);

	const handleOnPrintFabClick = useCallback(() => {
		const densicalcData = DensiCalcDataRepository.findDensiCalcData(
			selectedWell.id
		);

		const url =
			window.origin +
			'/print?well=' +
			btoa(JSON.stringify(selectedWell)) +
			'&densiCalcData=' +
			btoa(JSON.stringify(densicalcData));
		window.open(url, '_blank');
	}, [selectedWell]);

	return (
		<Fab
			sx={{
				position: 'fixed',
				bottom: 15,
				right: 15,
				backgroundColor: colors.primaryColor,
				color: 'white',
				':hover': {
					backgroundColor: '#E0E0E0',
					color: 'rgba(0, 0, 0, 0.87)'
				}
			}}
			onClick={handleOnPrintFabClick}
		>
			<PrintIcon />
		</Fab>
	);
}
