import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useLoadDensiCalcData } from '../hooks/useLoadDensiCalcData';
import { useLoadWells } from '../hooks/useLoadWells';
import { useSaveDensiCalcData } from '../hooks/useSaveDensiCalcData';
import ABTests from '../pages/ABTests';
import DensiCalc from '../pages/DensiCalc';
import Print from '../pages/Print';

export default function _Routes() {
	useLoadWells();
	useLoadDensiCalcData();
	useSaveDensiCalcData();

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<DensiCalc />} />
				<Route path="/print" element={<Print />} />
				<Route path="/ab" element={<ABTests />} />
			</Routes>
		</BrowserRouter>
	);
}
