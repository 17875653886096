import { useEffect, useContext } from 'react';
import { DensiCalcContext } from '../providers/DensiCalcProvider';
import { WellContext } from '../providers/WellProvider';
import DensiCalcDataRepository from '../repositories/DensiCalcDataRepository';

export function useSaveDensiCalcData() {
	const {
		density,
		densityUnit,
		measuredAtTemp,
		measuredAtTempUnit,
		referenceTemperature,
		referenceTemperatureUnit,
		resultDensity,
		resultDensityUnit,
		densityAtRefTemp,
		densityAtRefTempUnit,
		rigType,
		airgap,
		airgapUnit,
		rkbToFlowline,
		rkbToFlowlineUnit,
		waterDepth,
		waterDepthUnit,
		surfaceTemperature,
		surfaceTemperatureUnit,
		seabedTemperature,
		seabedTemperatureUnit,
		downholeTemperature,
		downholeTemperatureUnit,
		downholeTempMeasuredAtDepth,
		downholeTempMeasuredAtDepthUnit,
		analysisPoint,
		analysisPointUnit,
		averageRiserDensity,
		averageRiserDensityUnit,
		fluidPressureAtSeabed,
		fluidPressureAtSeabedUnit,
		riserMargin,
		riserMarginUnit,
		avgDensityAboveTvd,
		avgDensityAboveTvdUnit,
		pressureAtTvd,
		pressureAtTvdUnit,
		temperatureAtTvd,
		temperatureAtTvdUnit
	} = useContext(DensiCalcContext);

	const { selectedWell } = useContext(WellContext);

	// Effect to save the data when any field change.
	useEffect(() => {
		DensiCalcDataRepository.saveDensiCalcData(selectedWell.id, {
			density,
			densityUnit,
			measuredAtTemp,
			measuredAtTempUnit,
			referenceTemperature,
			referenceTemperatureUnit,
			resultDensity,
			resultDensityUnit,
			densityAtRefTemp,
			densityAtRefTempUnit,
			rigType,
			airgap,
			airgapUnit,
			rkbToFlowline,
			rkbToFlowlineUnit,
			waterDepth,
			waterDepthUnit,
			surfaceTemperature,
			surfaceTemperatureUnit,
			seabedTemperature,
			seabedTemperatureUnit,
			downholeTemperature,
			downholeTemperatureUnit,
			downholeTempMeasuredAtDepth,
			downholeTempMeasuredAtDepthUnit,
			analysisPoint,
			analysisPointUnit,
			averageRiserDensity,
			averageRiserDensityUnit,
			fluidPressureAtSeabed,
			fluidPressureAtSeabedUnit,
			riserMargin,
			riserMarginUnit,
			avgDensityAboveTvd,
			avgDensityAboveTvdUnit,
			pressureAtTvd,
			pressureAtTvdUnit,
			temperatureAtTvd,
			temperatureAtTvdUnit
		});
	}, [
		density,
		densityUnit,
		measuredAtTemp,
		measuredAtTempUnit,
		referenceTemperature,
		referenceTemperatureUnit,
		resultDensity,
		resultDensityUnit,
		densityAtRefTemp,
		densityAtRefTempUnit,
		rigType,
		airgap,
		airgapUnit,
		rkbToFlowline,
		rkbToFlowlineUnit,
		waterDepth,
		waterDepthUnit,
		surfaceTemperature,
		surfaceTemperatureUnit,
		seabedTemperature,
		seabedTemperatureUnit,
		downholeTemperature,
		downholeTemperatureUnit,
		downholeTempMeasuredAtDepth,
		downholeTempMeasuredAtDepthUnit,
		analysisPoint,
		analysisPointUnit,
		averageRiserDensity,
		averageRiserDensityUnit,
		fluidPressureAtSeabed,
		fluidPressureAtSeabedUnit,
		riserMargin,
		riserMarginUnit,
		avgDensityAboveTvd,
		avgDensityAboveTvdUnit,
		pressureAtTvd,
		pressureAtTvdUnit,
		temperatureAtTvd,
		temperatureAtTvdUnit
	]);
}
