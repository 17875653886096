import { SxProps } from '@mui/material';
import React, { createContext, useState } from 'react';
import { Theme as EmotionTheme } from '@emotion/react';

interface Theme {
	colors: Colors;
	setColors: React.Dispatch<React.SetStateAction<Colors>>;
}

interface Colors {
	warningColor: string;
	errorColor: string;
	resultColor: string;
	notRequiredColor: string;
	primaryColor: string;
}

export const defaultColors: Colors = {
	warningColor: '#FBBC05',
	errorColor: '#fc7b54',
	resultColor: '#BEE2E9',
	notRequiredColor: '#D9D9D9',
	primaryColor: '#164194'
};

const defaultTheme: Theme = {
	colors: defaultColors,
	setColors: () => {}
};

export const errorInputTheme: SxProps<EmotionTheme> = {
	input: {
		color: defaultColors.errorColor
	},
	'& label': {
		color: defaultColors.errorColor
	},
	'& label.Mui-focused': {
		color: defaultColors.errorColor
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: defaultColors.errorColor
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: defaultColors.errorColor
		},
		'&:hover fieldset': {
			borderColor: defaultColors.errorColor
		},
		'&.Mui-focused fieldset': {
			borderColor: defaultColors.errorColor
		}
	}
};

export const ThemeContext = createContext<Theme>(defaultTheme);

interface ThemeProviderProps {
	children: React.ReactNode;
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
	let [colors, setColors] = useState<Colors>(defaultColors);

	return (
		<ThemeContext.Provider value={{ colors, setColors }}>
			{children}
		</ThemeContext.Provider>
	);
}
