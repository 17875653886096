export const addCommas = (nStr: string) => {
	nStr = nStr.replace(/[,]/gi, '');
	nStr += '';
	const x = nStr.split('.');
	let x1 = x[0];
	const x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2');
	}
	return x1 + x2;
};

export const convertToNumber = (str: string | number): number => {
	if (typeof str === 'number') return str;

	str = str.replaceAll(',', '');
	return Number(str);
};

export const DEFAULT_SIGNIFICANT_FIGURES = 4;
