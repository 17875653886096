import { useContext } from 'react';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeContext } from '../../../providers/ThemeProvider';

type UngroupedButtonsProps = {
	onEditButtonClick: () => void;
	onDeleteButtonClick: () => void;
};
export default function UngroupedButtons(props: UngroupedButtonsProps) {
	const { colors } = useContext(ThemeContext);

	return (
		<>
			<Fab
				sx={{
					position: 'fixed',
					bottom: 15,
					right: 90,
					backgroundColor: '#E0E0E0',
					color: 'rgba(0, 0, 0, 0.87)',
					':hover': {
						color: 'white',
						backgroundColor: colors.primaryColor
					}
				}}
				onClick={props.onEditButtonClick}
			>
				<EditIcon />
			</Fab>
			<Fab
				sx={{
					position: 'fixed',
					bottom: 15,
					right: 165,
					backgroundColor: '#E0E0E0',
					color: 'rgba(0, 0, 0, 0.87)',
					':hover': {
						color: 'white',
						backgroundColor: colors.primaryColor
					}
				}}
				onClick={props.onDeleteButtonClick}
			>
				<DeleteIcon />
			</Fab>
		</>
	);
}
