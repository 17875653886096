import { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { WellContext } from '../../../providers/WellProvider';
import WellRepository from '../../../repositories/WellRepository';
import DensiCalcDataRepository from '../../../repositories/DensiCalcDataRepository';

type DeleteWellDialogProps = {
	open: boolean;
	onClose: () => void;
};
type Severity = 'error' | 'info' | 'success' | 'warning';
type AlertProps = {
	open: boolean;
	text: string;
	severity: Severity;
};
export function DeleteWellDialog(props: DeleteWellDialogProps) {
	const { selectedWell, deleteWell, selectWell } = useContext(WellContext);
	const [alert, setAlert] = useState<AlertProps>({
		open: false,
		text: '',
		severity: 'info'
	});

	const openAlert = (text: string, severity: Severity) => {
		setAlert({ open: true, text, severity });
	};

	const closeAlert = () => {
		setAlert({ open: false, text: '', severity: 'info' });
	};

	const handleOnCancelButtonClick = () => {
		props.onClose();
	};

	const handleOnDeleteButtonClick = () => {
		try {
			WellRepository.deleteWell(selectedWell);
			DensiCalcDataRepository.deleteDensiCalcData(selectedWell.id);
			deleteWell(selectedWell);
			selectWell(
				WellRepository.findWells().sort((a, b) => a.id - b.id)[0]
			);

			openAlert('Well deleted successfully.', 'success');
		} catch (error: any) {
			const message = error.message ? error.message : error;
			openAlert('Error deleting well: ' + message, 'error');
		} finally {
			props.onClose();
		}
	};

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose}>
				<DialogTitle>Delete selected well</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete "
						{selectedWell.description}" and all the data associated
						with it? This action is irreversible.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleOnCancelButtonClick}>Cancel</Button>
					<Button onClick={handleOnDeleteButtonClick} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				onClose={closeAlert}
				open={alert.open}
				autoHideDuration={6000}
			>
				<Alert
					onClose={closeAlert}
					severity={alert.severity}
					sx={{ width: '100%' }}
				>
					{alert.text}
				</Alert>
			</Snackbar>
		</>
	);
}
