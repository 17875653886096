import { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { WellContext } from '../../../providers/WellProvider';
import { Well } from '../../../model/Well';
import WellRepository from '../../../repositories/WellRepository';

const WELL_DESCRIPTION_MAX_SIZE = 50;

interface CreateWellDialogProps {
	open: boolean;
	onClose: () => void;
}
type Severity = 'error' | 'info' | 'success' | 'warning';
type AlertProps = {
	open: boolean;
	text: string;
	severity: Severity;
};
export default function EditWellDialog(props: CreateWellDialogProps) {
	const { selectedWell } = useContext(WellContext);

	const [wellDescription, setWellDescription] = useState<string>(
		selectedWell.description
	);

	useEffect(
		() => setWellDescription(selectedWell.description),
		[selectedWell, setWellDescription]
	);

	const [alert, setAlert] = useState<AlertProps>({
		open: false,
		text: '',
		severity: 'info'
	});

	const openAlert = (text: string, severity: Severity) => {
		setAlert({ open: true, text, severity });
	};

	const closeAlert = () => {
		setAlert({ open: false, text: '', severity: 'info' });
	};

	const { editWell } = useContext(WellContext);

	const handleOnSaveWellButtonClick = () => {
		try {
			const newWell: Well = {
				...selectedWell,
				description: wellDescription
			};

			WellRepository.saveWell(newWell);
			editWell(newWell);
			setWellDescription('');

			openAlert('Well edited successfully.', 'success');
		} catch (error: any) {
			const message = error.message ? error.message : error;
			openAlert('Error editing well: ' + message, 'error');
		} finally {
			props.onClose();
		}
	};

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose}>
				<DialogTitle>Edit well</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Enter the new description of the well and click on the
						save button.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="edit_well_description"
						label="Well description"
						variant="standard"
						helperText={`${wellDescription.length} of ${WELL_DESCRIPTION_MAX_SIZE}.`}
						value={wellDescription}
						onChange={(event) => {
							if (
								event.target.value.length <=
								WELL_DESCRIPTION_MAX_SIZE
							)
								setWellDescription(event.target.value);
						}}
						required
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button color="error" onClick={props.onClose}>
						Cancel
					</Button>
					<Button
						onClick={handleOnSaveWellButtonClick}
						disabled={wellDescription.length === 0}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={alert.open}
				autoHideDuration={6000}
				onClose={closeAlert}
			>
				<MuiAlert
					onClose={closeAlert}
					severity={alert.severity}
					sx={{ width: '100%' }}
				>
					{alert.text}
				</MuiAlert>
			</Snackbar>
		</>
	);
}
