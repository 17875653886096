import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { TemperatureUnit, TEMP_UNITS } from '@fastsheep/densicalc-core';
import { SxProps, Theme } from '@mui/material';

type TemperatureUnitSelectorProps = {
	value: TemperatureUnit;
	onChange: (value: TemperatureUnit) => void;
	sx?: SxProps<Theme>;
	selectorSx?: SxProps<Theme>;
	id?: string;
	label?: string;
	size?: 'small' | 'medium' | undefined;
	disabled?: boolean;
};
export default function TemperatureUnitSelector(
	props: TemperatureUnitSelectorProps
) {
	const onChange = (
		event: SelectChangeEvent<TemperatureUnit>,
		child: React.ReactNode
	) => {
		props.onChange(event.target.value as TemperatureUnit);
	};

	// @ts-ignore
	const width = props.sx && props.sx.width ? props.sx.width : '10vw';
	return (
		<FormControl sx={{ ...props.sx, width: width }}>
			<InputLabel id="density_unit_label">
				{props.label ? props.label : ''}
			</InputLabel>
			<Select
				labelId="density_unit_label"
				size={props.size ? props.size : 'small'}
				label={props.label ? props.label : ''}
				name={props.id ? props.id : 'density_unit'}
				id={props.id ? props.id : 'density_unit'}
				data-cy={props.id ? props.id : 'length_unit'}
				value={props.value}
				onChange={onChange}
				sx={props.selectorSx}
				disabled={props.disabled ? props.disabled : false}
			>
				{TEMP_UNITS.map((unit, idx) => (
					<MenuItem
						key={idx}
						value={unit.code}
						id={unit.code}
						data-cy={unit.code}
					>
						{unit.displayName}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
