import { useContext } from 'react';
import Stack from '@mui/material/Stack';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import InputRow from '../InputRow';
import DensityUnitSelector from '../selectors/DensityUnitSelector';
import TemperatureUnitSelector from '../selectors/TemperatureUnitSelector';
import { findTempUnitDisplayName } from '../../utils/UnitUtils';
import {
	DensiCalcContext,
	isDensityValid
} from '../../providers/DensiCalcProvider';
import Calculator from '../../model/Calculator';
import { ThemeContext, errorInputTheme } from '../../providers/ThemeProvider';
import Section from '../Section';
import {
	DEFAULT_SIGNIFICANT_FIGURES,
	addCommas
} from '../../utils/NumberUtils';
import { getDefaultDensiCalcOptions } from '../../model/Options';
import DensiCalcOptionsRepository from '../../repositories/DensiCalcOptionsRepository';
import TextField from '../TextField';

export default function FluidDensityTempCorrection() {
	const savedOptions = DensiCalcOptionsRepository.getDensiCalcOptions();
	const options = savedOptions ? savedOptions : getDefaultDensiCalcOptions();

	const {
		density,
		densityUnit,
		measuredAtTemp,
		measuredAtTempUnit,
		referenceTemperature,
		referenceTemperatureUnit,
		setDensity,
		setDensityUnit,
		setMeasuredAtTemp,
		setMeasuredAtTempUnit,
		setReferenceTemperature,
		setReferenceTemperatureUnit,
		resultDensity,
		resultDensityUnit,
		densityAtRefTemp,
		densityAtRefTempUnit,
		setResultDensityUnit,
		setDensityAtRefTempUnit
	} = useContext(DensiCalcContext);

	const { colors } = useContext(ThemeContext);

	const selectorsStyle: SxProps<Theme> = {
		width: '10vh',
		marginLeft: options.spaceBetweenFieldAndSelectors ? '10px' : undefined
	};

	return (
		<Section title="Fluid density temperature correction">
			<Stack direction="column" spacing={2}>
				<InputRow>
					<TextField
						id="fluid_density"
						label="Fluid density"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={density}
						onChange={(event) =>
							setDensity(addCommas(event.target.value))
						}
						sx={
							isDensityValid(density, densityUnit) ||
							density === ''
								? undefined
								: errorInputTheme
						}
					/>
					<DensityUnitSelector
						id="fluid_density_unit_selector"
						value={densityUnit}
						onChange={(value) => setDensityUnit(value)}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="fluid_temperature"
						label="Measured at"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={measuredAtTemp}
						onChange={(event) =>
							setMeasuredAtTemp(addCommas(event.target.value))
						}
					/>
					<TemperatureUnitSelector
						id="fluid_temperature_unit_selector"
						value={measuredAtTempUnit}
						onChange={(value) => setMeasuredAtTempUnit(value)}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="density_at_15_6_c_60_f"
						label="Density at 15.6°C/60°F"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							resultDensity,
							resultDensityUnit,
							DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
					/>
					<DensityUnitSelector
						id="density_at_15_6_c_60_f_unit_selector"
						value={resultDensityUnit}
						onChange={(value) => setResultDensityUnit(value)}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="reference_temperature"
						label="Reference temperature"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={referenceTemperature}
						onChange={(event) =>
							setReferenceTemperature(
								addCommas(event.target.value)
							)
						}
					/>
					<TemperatureUnitSelector
						id="reference_temperature_unit_selector"
						value={referenceTemperatureUnit}
						onChange={(value) => setReferenceTemperatureUnit(value)}
						sx={selectorsStyle}
					/>
				</InputRow>
				<InputRow>
					<TextField
						id="density_at_ref_temp"
						label={`Density at ${referenceTemperature}${findTempUnitDisplayName(
							referenceTemperatureUnit
						)}`}
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						value={Calculator.handleOutput(
							densityAtRefTemp,
							densityAtRefTempUnit,
							DEFAULT_SIGNIFICANT_FIGURES
						)}
						sx={{
							backgroundColor: colors.resultColor
						}}
					/>
					<DensityUnitSelector
						id="density_at_ref_temp_unit_selector"
						value={densityAtRefTempUnit}
						onChange={(value) => setDensityAtRefTempUnit(value)}
						sx={selectorsStyle}
						selectorSx={{
							backgroundColor: options.coloredOutputSelectors
								? colors.resultColor
								: undefined
						}}
					/>
				</InputRow>
			</Stack>
		</Section>
	);
}
